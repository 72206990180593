import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import i18nContext from 'components/i18n-context';
import { CardSettings } from './components/CardSettings';
import { CardExample } from '../CardExample';
import { ReactComponent as DetailsIcon } from 'assets/card-details.svg';
import { ReactComponent as ReplaceIcon } from 'assets/card-replace.svg';
import { ReactComponent as BlockIcon } from 'assets/card-block.svg';
import { ReactComponent as UnlockIcon } from 'assets/card-unlock.svg';
import {
  CARD_ACTIONS,
  CARD_BLOCK_TYPE,
  CARD_CREATION_FORM_TYPE,
  CARD_STATUS,
  REPLACE_ACTIONS
} from 'components/common/constants';
import { PopUpBlockOrUnblockCardConfirmationScheme } from 'components/common/PopUpScheme/PopUpBlockOrUnblockCardConfirmationScheme';
import { PopUpCardDetailsScheme } from 'components/common/PopUpScheme/PopUpCardDetailsScheme';
import { PopUpReplaceCardConfirmationScheme } from 'components/common/PopUpScheme/PopUpReplaceCardConfirmationScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { getErrorMessageForAlert, MEDIA_QUERY } from 'services/utils';
import { ROUTE_PATHS as ROUTH_PATHS } from 'routes/constants';
import Alert from 'uikit/Alert/Alert';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import '../../Cards.scss';
import PopUpSuccessScheme from '../../../common/PopUpScheme/PopUpSuccessScheme';
import { filterWallesterAccounts } from '../../utils';

const VirtualCardSettings = ({ customerType, customerNumber, cardsStore, card, userStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const { id } = useParams();
  const [isShowBlockOrUnblockCardPopUp, setIsShowBlockOrUnblockCardPopUp] = useState(false);
  const [isShowReplaceCardPopUp, setIsShowReplaceCardPopUp] = useState(false);
  const [isShowDetailsPopUp, setIsShowDetailsPopUp] = useState(false);
  const [cardActionType, setCardActionType] = useState(null);
  const isBlocked = card?.status === (CARD_STATUS.SOFT_BLOCK || CARD_STATUS.HARD_BLOCK);
  const isClosed = card?.status === CARD_STATUS.CLOSED;
  const isBlockPlug = true;

  useEffect(() => {
    if (id && cardsStore.isInitialized && cardsStore.cards) {
      cardsStore.clearError();
      const cardInfo = cardsStore.getCardById(id);
      if (!cardInfo) {
        navigate('/404');
      } else {
        cardsStore.setSelectedCard(cardsStore.getCardById(id));
      }
    }
  }, [id, cardsStore.cards, cardsStore.isInitialized]);

  useEffect(() => {
    if (cardsStore.error && isShowDetailsPopUp) {
      setIsShowDetailsPopUp(false);
    }
  }, [cardsStore.error, isShowDetailsPopUp]);

  const handeDetailsButtonClick = () => {
    cardsStore.clearError();
    cardsStore.resetCardData();
    setIsShowDetailsPopUp(true);
    cardsStore.getCardNumber(customerNumber, id);
    cardsStore.getCardCVV(customerNumber, id);
  };

  const handeDetailsPopUpClose = () => {
    cardsStore.resetCardData();
    setIsShowDetailsPopUp(false);
  };

  const handleBlockCardButtonClick = () => {
    cardsStore.clearError();
    setIsShowBlockOrUnblockCardPopUp(true);
    setCardActionType(CARD_ACTIONS.BLOCK);
  };

  const handleUnblockCardButtonClick = () => {
    cardsStore.clearError();
    setIsShowBlockOrUnblockCardPopUp(true);
    setCardActionType(CARD_ACTIONS.UNBLOCK);
  };

  const onConfirmCardBlock = async () => {
    if (cardActionType === CARD_ACTIONS.BLOCK) {
      await cardsStore.blockCard(customerNumber, id);
    } else {
      await cardsStore.unblockCard(customerNumber, id);
    }
    setIsShowBlockOrUnblockCardPopUp(false);
    setCardActionType(null);
  };

  const handleReplaceButtonClick = () => {
    cardsStore.clearError();
    setCardActionType(CARD_ACTIONS.REPLACE);
    setIsShowReplaceCardPopUp(true);
  };

  const onConfirmReplaceCard = async () => {
    await cardsStore.replaceCard(customerNumber, id).then(() => {
      if (cardsStore.error) {
        setCardActionType(null);
        setIsShowReplaceCardPopUp(false);
      }
      setCardActionType(CARD_ACTIONS.SUCCESS);
    });
  };

  const handleShowMyCardsClick = async () => {
    const wallesterAccounts = filterWallesterAccounts(userStore.userAccounts);
    cardsStore.clearError();
    setCardActionType(null);
    setIsShowReplaceCardPopUp(false);
    await cardsStore.initializeStoreState(customerNumber, wallesterAccounts);
    navigate(ROUTH_PATHS.CARDS);
  };

  return (
    <Container
      className={!isMobile ? 'container-web' : ''}
      header={i18n.getMessage('cards.container.virtualCardDetails', { cardNumber: card?.card_number })}
      isMaxWidthUnset={true}
    >
      <Alert className='card-details-alert' type='warning' message={getErrorMessageForAlert(i18n, cardsStore.error)} />
      <div className='card-settings-wrapper'>
        <div className='card-settings-card-wrapper'>
          <CardExample
            className='card-settings-card-example'
            customerType={customerType}
            cardType={CARD_CREATION_FORM_TYPE.VIRTUAL}
            cardStatus={card?.status}
            cardholderName={card?.name}
          />
          <div className='card-settings-card-actions-wrapper'>
            <div className={`card-settings-card-button-wrapper ${isBlocked || isClosed ? 'button-disabled' : ''}`}>
              <button
                className='card-settings-card-button'
                onClick={handeDetailsButtonClick}
                disabled={isBlocked || isClosed}
              >
                <DetailsIcon />
              </button>
              <span>{i18n.getMessage('cards.details.virtual.button.details')}</span>
            </div>
            <div className={`card-settings-card-button-wrapper ${isBlocked || isClosed ? 'button-disabled' : ''}`}>
              <button
                className='card-settings-card-button'
                disabled={isBlocked || isClosed}
                onClick={handleReplaceButtonClick}
              >
                <ReplaceIcon />
              </button>
              <span>{i18n.getMessage('cards.details.virtual.button.replace')}</span>
            </div>
            {isBlocked ? (
              <div
                className={`card-settings-card-button-wrapper ${
                  card?.block_type !== CARD_BLOCK_TYPE.BLOCKED_BY_CARDHOLDER ? 'button-disabled' : ''
                }`}
              >
                <button
                  className='card-settings-card-button'
                  onClick={handleUnblockCardButtonClick}
                  disabled={card?.block_type !== CARD_BLOCK_TYPE.BLOCKED_BY_CARDHOLDER}
                >
                  <UnlockIcon />
                </button>
                <span>{i18n.getMessage('cards.details.virtual.button.unlock')}</span>
              </div>
            ) : (
              <div className={`card-settings-card-button-wrapper ${isClosed ? 'button-disabled' : ''}`}>
                <button className='card-settings-card-button' onClick={handleBlockCardButtonClick} disabled={isClosed}>
                  <BlockIcon />
                </button>
                <span>{i18n.getMessage('cards.details.virtual.button.block')}</span>
              </div>
            )}
          </div>
        </div>
        <CardSettings isCardBlocked={isBlockPlug} />
      </div>
      <PopUp
        alignOnCenter
        show={isShowBlockOrUnblockCardPopUp || isShowReplaceCardPopUp || isShowDetailsPopUp}
        className={isShowDetailsPopUp ? 'cardDetails' : ''}
      >
        {isShowDetailsPopUp ? (
          <PopUpCardDetailsScheme
            onClose={handeDetailsPopUpClose}
            cardholderName={card?.name}
            cardNumber={cardsStore.cardNumber}
            expirationDate={card?.expiration_date}
            cvv={cardsStore.cvv}
          />
        ) : isShowBlockOrUnblockCardPopUp ? (
          <PopUpBlockOrUnblockCardConfirmationScheme
            type={cardActionType}
            onClose={() => setIsShowBlockOrUnblockCardPopUp(false)}
            onConfirm={onConfirmCardBlock}
          />
        ) : isShowReplaceCardPopUp && cardActionType === CARD_ACTIONS.REPLACE ? (
          <PopUpReplaceCardConfirmationScheme
            type={cardActionType}
            onClose={() => setIsShowReplaceCardPopUp(false)}
            onConfirm={onConfirmReplaceCard}
          />
        ) : (
          <PopUpSuccessScheme
            className={'replace-card-success-wrapper'}
            title={i18n.getMessage('cards.popUp.replaceCard.replaced.title')}
            message={i18n.getMessage('cards.popUp.replaceCard.replaced.description')}
            buttonText={i18n.getMessage('cards.popUp.replaceCard.replaced.btn.showMyCards')}
            onClose={handleShowMyCardsClick}
          />
        )}
      </PopUp>
    </Container>
  );
};

VirtualCardSettings.propTypes = {
  customerNumber: PropTypes.string,
  customerType: PropTypes.string,
  card: PropTypes.object,
  cardsStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  customerType: stores.userStore.userData.account?.type,
  cardsStore: stores.cardsStore,
  card: stores.cardsStore.selectedCard,
  userStore: stores.userStore
}))(observer(VirtualCardSettings));
