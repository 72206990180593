import moment from 'moment';

export const PREDEFINED_FILTERS = {
  TODAY: 'filterToday',
  YESTERDAY: 'filterYesterday',
  WEEK: 'filterThisWeek',
  LAST_WEEK: 'filterLastWeek',
  MONTH: 'filterThisMonth',
  LAST_MONTH: 'filterLastMonth'
};

export const getFromDate = (range, minDate) => {
  const minDateMoment = minDate ? moment(minDate) : null;

  const adjustDate = (date) => {
    if (minDateMoment && date.isBefore(minDateMoment)) {
      return minDateMoment;
    }
    return date;
  };

  switch (range) {
    case PREDEFINED_FILTERS.TODAY:
      return {
        from: moment().toISOString(),
        to: moment().toISOString()
      };
    case PREDEFINED_FILTERS.YESTERDAY:
      return {
        from: adjustDate(moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days')).toISOString(),
        to: adjustDate(moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days')).toISOString()
      };
    case PREDEFINED_FILTERS.WEEK:
      return {
        from: adjustDate(moment().startOf('isoWeek')).toISOString(),
        to: moment().toISOString()
      };
    case PREDEFINED_FILTERS.LAST_WEEK:
      return {
        from: adjustDate(moment().subtract(1, 'weeks').startOf('isoWeek')).toISOString(),
        to: adjustDate(moment().subtract(1, 'weeks').endOf('isoWeek')).toISOString()
      };
    case PREDEFINED_FILTERS.MONTH:
      return {
        from: adjustDate(moment().startOf('month')).toISOString(),
        to: moment().toISOString()
      };
    case PREDEFINED_FILTERS.LAST_MONTH:
      return {
        from: adjustDate(moment().subtract(1, 'month').startOf('month')).toISOString(),
        to: adjustDate(moment().subtract(1, 'month').endOf('month')).toISOString()
      };
    default:
      return null;
  }
};

export const isFilterDisabled = (filterName, minDate) => {
  if (!minDate) return false;
  const minDateMoment = moment(minDate);

  switch (filterName) {
    case PREDEFINED_FILTERS.YESTERDAY:
      return minDateMoment.isAfter(moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days'));
    case PREDEFINED_FILTERS.LAST_WEEK:
      return minDateMoment.isAfter(moment().subtract(1, 'weeks').endOf('isoWeek'));
    case PREDEFINED_FILTERS.LAST_MONTH:
      return minDateMoment.isAfter(moment().subtract(1, 'month').endOf('month'));
    default:
      return false;
  }
};
