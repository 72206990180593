import { CUSTOMER_TYPE, MESSAGE_SENDER_TYPES } from '../common/constants';

export const shortenFilename = (filename) => {
  if (filename.length <= 17) {
    return filename;
  }

  const extensionStartIndex = filename.lastIndexOf('.');
  const filenameWithoutExtension = filename.substring(0, extensionStartIndex);
  // eslint-disable-next-line max-len
  return `${filenameWithoutExtension.slice(0, 7)}...${filenameWithoutExtension.slice(-3)}.${filename.slice(
    extensionStartIndex + 1
  )}`;
};

export const getSenderName = (senderType, userAccount) => {
  let senderName;

  if (senderType === MESSAGE_SENDER_TYPES.USER && userAccount?.type === CUSTOMER_TYPE.COMPANY) {
    senderName = userAccount?.details.company_name;
  } else if (senderType === MESSAGE_SENDER_TYPES.USER && userAccount?.type === CUSTOMER_TYPE.INDIVIDUAL) {
    senderName = `${userAccount?.details.name} ${userAccount?.details.surname}`;
  } else {
    senderName = process.env.REACT_APP_PAYMENT_INSTITUTION;
  }

  return senderName;
};

export const isJustNowMessageCheck = (createdAt) => {
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;
  const now = new Date();
  const createdAtTime = new Date(createdAt);

  return now - createdAtTime < fiveMinutesInMilliseconds;
};

export const animationScrollToTop = (ref) => {
  if (!ref.current) return;
  const scrollTop = ref.current.scrollTop;
  if (scrollTop > 0) {
    ref.current.scrollTop -= 50;
    window.requestAnimationFrame(() => animationScrollToTop(ref));
    ref.current.scrollTop = scrollTop - scrollTop / 8;
  }
};

export const animationScrollToBottom = (ref) => {
  if (!ref.current) return;
  const { scrollHeight, clientHeight, scrollTop } = ref.current;
  const distance = scrollHeight - clientHeight - scrollTop;
  if (distance > 0) {
    ref.current.scrollTop += distance / 8;
    window.requestAnimationFrame(() => animationScrollToBottom(ref));
  }
};

export const convertHtmlToString = (value) => {
  const cleanedString = value.toString('html').replace(/<[^>]+>/g, '');
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = cleanedString;

  return tempElement.value;
};

export const convertMessageToHtmlAndTrimSpaces = (message) => {
  return message.toString('html').replace(/&nbsp;/g, '');
};

export const getUnreadTopicsAmount = (topicArray) => {
  let unreadCount = 0;
  for (const topic of topicArray) {
    if (topic.read === false) {
      unreadCount++;
    }
  }
  return unreadCount;
};
