import { Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Footer from 'components/common/Footer';
import Loader from 'components/common/Loader';
import { isTokenValid } from 'services/authUtils';
import initializeAnalytics from 'services/analytics';
import { INITIAL_PATH_AFTER_LOGIN } from 'routes/constants';
import CustomFormSlider from 'uikit/CustomFormSlider/CustomFormStepper';

const logoPath = process.env.REACT_APP_LOGO_PATH;

const AuthorizationLayout = ({ isRegistration, authStore, registrationStore }) => {
  useEffect(() => {
    if (!isTokenValid()) {
      initializeAnalytics();
    }
  }, []);

  useEffect(() => {
    if (!authStore.isLoading && authStore.isLoginSuccess && authStore.isPinSuccess) {
      window.location.replace(INITIAL_PATH_AFTER_LOGIN);
    }
  }, [authStore.isLoading, authStore.isLoginSuccess, authStore.isPinSuccess]);

  return (
    <div className='page authorization'>
      {!authStore.isLoading && authStore.isLoginSuccess && authStore.isPinSuccess ? (
        <Loader className='application-loader' />
      ) : (
        <Fragment>
          <main className='content'>
            <a
              href={process.env.REACT_APP_LINK_HOME_PAGE}
              target='_blank'
              rel='noreferrer'
              className='icon-logo-wrapper'
            >
              <img src={logoPath} alt='Logo' className='icon-logo' />
            </a>
            {isRegistration && (
              <CustomFormSlider steps={[{}, {}, {}, {}]} currentStep={registrationStore.currentRegistrationStep} />
            )}
            <Outlet />
          </main>
          <Footer />
        </Fragment>
      )}
    </div>
  );
};

AuthorizationLayout.propTypes = {
  isRegistration: PropTypes.any,
  authStore: MobXPropTypes.observableObject,
  registrationStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore,
  registrationStore: stores.registrationStore
}))(observer(AuthorizationLayout));
