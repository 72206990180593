import { replaceSpaces, strToFloat, trimTextContent } from 'services/utils';
import { PAYMENT_METHOD } from 'components/common/constants';

export default ({ providerTableData, data }) => ({
  sender_wallet: (data.wallet && data.wallet?.wallet_number?.trim()) || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  currency: (data.wallet && data.wallet?.currency?.trim()) || '',
  transaction_recipient: {
    recipient_type: providerTableData.recipient_type.toUpperCase(),
    registration_number: providerTableData.registration_number,
    beneficiary_city: providerTableData.beneficiary_city,
    beneficiary_country: providerTableData.beneficiary_country,
    beneficiary_post_code: providerTableData.beneficiary_post_code,
    beneficiary_address: providerTableData.beneficiary_address,
    beneficiary: {
      type: providerTableData.recipient_type.toUpperCase(),
      ...(providerTableData.recipient_type === 'Company'
        ? {
            company_name: trimTextContent(providerTableData?.company_name) || ''
          }
        : {
            first_name: trimTextContent(providerTableData?.first_name) || '',
            last_name: trimTextContent(providerTableData?.last_name) || ''
          })
    },
    requisites:
      data.paymentMethod === PAYMENT_METHOD.SWIFT || data.paymentMethod === PAYMENT_METHOD.TARGET2
        ? {
            iban: replaceSpaces(providerTableData?.recipient_wallet) || '',
            bank_country: data?.bank_country?.trim() || providerTableData?.bank_country?.trim(),
            bank_name: data?.bank_name?.trim() || providerTableData?.bank_name?.trim(),
            account_number: data?.account_number?.trim() || providerTableData?.account_number?.trim(),
            bic: data?.bic?.trim() || providerTableData?.bic?.trim()
          }
        : {
            iban: replaceSpaces(providerTableData?.recipient_wallet) || '',
            bank_country: data?.bank_country?.trim()
          }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: (data.wallet && data.wallet?.transfer_provider) || '',
  comment: trimTextContent(providerTableData?.comment) || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
