import React, { useContext } from 'react';
import { ActionButton } from 'uikit/ActionButton/ActionButton';
import './TemplatesTableRow.scss';
import i18nContext from 'components/i18n-context';

export const TemplatesTableRow = ({
  templateName,
  payeeRequisites,
  payeeName,
  onPaymentClick,
  onEditTemplateClick,
  onRemoveTemplateClick,
  tooltipText = []
}) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='templates-table-row-wrapper list '>
      <div className='templates-table-row-item template-name'>
        <p className='template-mobile-text'>{i18n.getMessage('templates.tableHeader.templateName')}</p>
        <p className='template-name'>{templateName}</p>
      </div>
      <div className='templates-table-row-item payee-requisites'>
        <p className='template-mobile-text'>{i18n.getMessage('templates.tableHeader.payeeRequisites')}</p>
        <p className='payee-requisites'>{payeeRequisites}</p>
      </div>
      <div className='templates-table-row-item payee-name'>
        <p className='template-mobile-text'>{i18n.getMessage('templates.tableHeader.payeeName')}</p>
        <p className='payee-name'>{payeeName}</p>
      </div>
      <div className='templates-table-row-item actions wrapper-icon'>
        <p className='template-mobile-text'>{i18n.getMessage('templates.tableHeader.actions')}</p>
        <div className='wrapper-icon'>
          <ActionButton tooltipText={tooltipText && tooltipText[0]} iconType='new-payment' onClick={onPaymentClick} />
          <ActionButton tooltipText={tooltipText && tooltipText[1]} iconType='edit' onClick={onEditTemplateClick} />
          <ActionButton tooltipText={tooltipText && tooltipText[2]} iconType='remove' onClick={onRemoveTemplateClick} />
        </div>
      </div>
    </div>
  );
};

TemplatesTableRow.propTypes = {};
