const STORED_TOKEN_NAMES = {
  ACCESS_TOKEN: 'accessToken',
  ACCESS_EXPIRES_ON: 'accessExpiresOn',
  REFRESH_TOKEN: 'refreshToken',
  TOKEN_TYPE: 'tokenType'
}; // check values in tokenize function (requestAgent.js)

export const LAST_USER_ACTIVITY_DATE_TIME = 'lastUserActivityDateTime';

export const LAST_SELECTED_ACCOUNT_NUMBER = 'lastSelectedAccountNumber';

export const removeAccessToken = () => window.localStorage.removeItem(STORED_TOKEN_NAMES.ACCESS_TOKEN);

export const getAccessToken = () => window.localStorage.getItem(STORED_TOKEN_NAMES.ACCESS_TOKEN);

export const getRefreshToken = () => window.localStorage.getItem(STORED_TOKEN_NAMES.REFRESH_TOKEN);

export const getAccessExpiresOn = () => window.localStorage.getItem(STORED_TOKEN_NAMES.ACCESS_EXPIRES_ON);

export const getTokenType = () => window.localStorage.getItem(STORED_TOKEN_NAMES.TOKEN_TYPE);

export const clearLocalStorage = () => window.localStorage.clear();

export const isTokenValid = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const accessExpiresOn = getAccessExpiresOn();

  return accessToken && refreshToken && accessExpiresOn > Date.now();
};

export const setConfirmationActionType = (type) => localStorage.setItem('CONFIRMATION_TYPE', type);

export const getConfirmationActionType = () => localStorage.getItem('CONFIRMATION_TYPE');

export const removeConfirmationActionType = () => localStorage.removeItem('CONFIRMATION_TYPE');

export const updateConfirmationActionType = (confirmationType) => {
  const currentConfirmationType = getConfirmationActionType();
  if (!currentConfirmationType || currentConfirmationType !== confirmationType) {
    localStorage.setItem('CONFIRMATION_TYPE', confirmationType);
  }
};

export const setSecureUserPhone = (phone) => localStorage.setItem('SECURE_PHONE', phone);

export const getSecureUserPhone = () => localStorage.getItem('SECURE_PHONE');

export const saveTokenInfo = (info) =>
  Object.entries(info).map(([key, value]) => window.localStorage.setItem(key, value));

export const setLastUserActivityDateTime = () => localStorage.setItem(LAST_USER_ACTIVITY_DATE_TIME, new Date());

export const resetLastUserActivityDateTime = () => localStorage.setItem(LAST_USER_ACTIVITY_DATE_TIME, '');

export const getLastUserActivityDateTime = () => localStorage.getItem(LAST_USER_ACTIVITY_DATE_TIME);

export const setSelectedMultiAccount = (accountNumber) =>
  localStorage.setItem(LAST_SELECTED_ACCOUNT_NUMBER, accountNumber);

export const getSelectedMultiAccount = () => localStorage.getItem(LAST_SELECTED_ACCOUNT_NUMBER);

export const logoutActiveSessionWithoutToken = () => {
  window.location = '/logout';
  clearLocalStorage();
};

export const isActiveSession = () =>
  // eslint-disable-next-line max-len
  new Date() - new Date(getLastUserActivityDateTime()) < process.env.REACT_APP_PROLONGATION_MODAL_APPEARING_TIME * 1000;
