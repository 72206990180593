/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import SumsubWebSdk from '@sumsub/websdk-react';
import i18nContext from 'components/i18n-context';
import { setUnderReviewStatus } from 'services/requestAgent';
import { Container } from 'uikit/Container/Container';
import './Verification.scss';

const Sumsub = ({ email, phone, customerNumber, levelName, accessToken, beginVerification }) => {
  const i18n = useContext(i18nContext);

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    await beginVerification(customerNumber, { level_name: levelName });
  };

  const handleOnMessage = async (type) => {
    if (type === 'idCheck.onApplicantSubmitted') {
      await setUnderReviewStatus(customerNumber);
    }
  };

  return (
    <section className='right-section'>
      <Container className='sumsub-verification-container' header={i18n.getMessage('container.verification')}>
        <div className='sumsub'>
          {accessToken && (
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={getAccessToken}
              config={{
                email: email,
                phone: phone
              }}
              onMessage={handleOnMessage}
            />
          )}
        </div>
      </Container>
    </section>
  );
};

Sumsub.propTypes = {
  email: PropTypes.string,
  customerNumber: PropTypes.string,
  levelName: PropTypes.string,
  accessToken: PropTypes.string,
  beginVerification: PropTypes.func
};

export default Sumsub;
