import { ReactComponent as LoadIcon } from './load-icon.svg';
import PropTypes from 'prop-types';
import { classNames } from '../utils';
import { TOOLTIP_DIRECTION } from 'components/common/constants';
import './FileDownloadButton.scss';

export const FileDownloadButton = ({ onClick, type, className, style, isDisabled, tooltipText, tooltipDirection }) => {
  const buttonClasses = classNames({
    'pdf-button': true,
    disabled: isDisabled,
    [className]: true,
    [style]: true
  });

  const handleClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
      return;
    }
    onClick(e);
  };

  return (
    <button className={buttonClasses} onClick={handleClick}>
      {tooltipText && (
        <div
          className={'pdf-button-tooltip'}
          style={tooltipDirection === TOOLTIP_DIRECTION.BOTTOM ? { top: '60px' } : { top: '-60px' }}
        >
          <p className={'pdf-button-tooltip-text'}>{tooltipText}</p>
        </div>
      )}
      {type}
      <LoadIcon className={'icon'} />
    </button>
  );
};

FileDownloadButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  isDisabled: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  tooltipDirection: PropTypes.string
};
