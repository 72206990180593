import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TransactionItem } from './TransactionItem';
import { TransactionItemLoader } from './TransactionItemLoader';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import Loader from 'components/common/Loader';
import { TRANSACTIONS_TABLE_HEADER } from 'components/common/constants';
import PopUpTransactionScheme from 'components/common/PopUpScheme/PopUpTransactionScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LatestTransactions.scss';

const complianceRequestsTableMaxHeight = 280;

const sortedHeaders = {
  date: { sort_column: 'updated_at', sort_direction: '' },
  fromTo: { sort_column: 'recipient_name', sort_direction: '' },
  amountCommission: { sort_column: 'amount', sort_direction: '' }
};

const TransactionsList = ({ compact, transactions, onChangeSort, sortData, isLoading, isComplianceRequests }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const [popUpState, setPopUpState] = useState({ open: false, transaction: {} });
  const tableWrapperRef = useRef(null);

  useEffect(() => {
    if (isComplianceRequests && tableWrapperRef.current) {
      if (tableWrapperRef.current.scrollHeight > complianceRequestsTableMaxHeight) {
        tableWrapperRef.current.style.overflowY = 'auto';
      } else {
        tableWrapperRef.current.style.overflowY = 'hidden';
      }
    }
  }, [isComplianceRequests, tableWrapperRef.current]);

  const showPopup = (transaction) => {
    setPopUpState({ open: true, transaction: transaction });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, transaction: {} });
  };

  const sortColumn = ({ sort_column }) =>
    onChangeSort({
      sort_column,
      sort_direction: sortData.sort_direction === 'ASC' ? 'DESC' : 'ASC'
    });

  const renderPopUp = () => {
    return (
      <>
        <PopUp className='transaction-info' show={popUpState.open} onClose={closePopUp}>
          <PopUpTransactionScheme selectedTransaction={popUpState.transaction} onClose={closePopUp} />
        </PopUp>
      </>
    );
  };

  if (isMobile) {
    return (
      <div className={isComplianceRequests ? 'compliance-requests-mobile-wrapper' : 'transaction-mobile-wrapper'}>
        {isLoading
          ? [...Array(7).keys()].map((loadTransaction) => (
              <TransactionItemLoader isMobile={isMobile} key={loadTransaction} />
            ))
          : transactions.map((transaction) => (
              <TransactionItem
                isMobile={isMobile}
                key={transaction.transaction_number + transaction.type}
                transaction={transaction}
                onInfoClick={() => showPopup(transaction)}
              />
            ))}
        {renderPopUp()}
      </div>
    );
  }

  return (
    <>
      <div
        className={isComplianceRequests ? 'compliance-requests-table-wrapper' : 'transaction-table-wrapper'}
        ref={tableWrapperRef}
      >
        <table className='transactions-wrapper'>
          <thead>
            <tr>
              {/* eslint-disable-next-line max-len */}
              {isLoading ? (
                <th colSpan={6} className={'transaction-loader-wrapper'}>
                  <Loader className={'transaction-loader'} />
                </th>
              ) : (
                TRANSACTIONS_TABLE_HEADER.map((header) => (
                  <th className={header} key={header}>
                    {header && i18n.getMessage(`latestTransactions.header.${header}`)}
                    {!compact && sortedHeaders[header] && (
                      <ArrowSelect
                        onClick={() => sortColumn(sortedHeaders[header])}
                        className={`arrow-icon ${
                          sortData.sort_column === sortedHeaders[header].sort_column && sortData.sort_direction
                        }`}
                      />
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? [...Array(7).keys()].map((loadTransaction) => <TransactionItemLoader key={loadTransaction} />)
              : transactions.map((transaction) => (
                  <TransactionItem
                    key={transaction.transaction_number + transaction.type}
                    transaction={transaction}
                    onInfoClick={() => showPopup(transaction)}
                  />
                ))}
          </tbody>
        </table>
      </div>
      {renderPopUp()}
    </>
  );
};

TransactionsList.propTypes = {
  transactions: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  onChangeSort: PropTypes.func,
  sortData: PropTypes.object,
  isLoading: PropTypes.bool,
  isComplianceRequests: PropTypes.bool
};

export default TransactionsList;
