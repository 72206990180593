import { replaceSpaces, strToFloat, trimTextContent } from 'services/utils';

export default ({ providerTableData, data }) => ({
  sender_wallet: (data.wallet && data.wallet?.wallet_number?.trim()) || '',
  currency: (data.wallet && data.wallet?.currency?.trim()) || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  transaction_recipient: {
    beneficiary: {
      type: providerTableData.recipient_type.toUpperCase(),
      ...(providerTableData.recipient_type === 'Company'
        ? {
            company_name: trimTextContent(providerTableData?.company_name) || ''
          }
        : {
            first_name: trimTextContent(providerTableData?.first_name) || '',
            last_name: trimTextContent(providerTableData?.last_name) || ''
          })
    },
    requisites: providerTableData?.recipient_wallet
      ? {
          iban: replaceSpaces(providerTableData?.recipient_wallet) || '',
          bank_country: data?.bank_country?.trim()
        }
      : {
          account_number: providerTableData?.account_number?.trim(),
          sort_code: providerTableData?.sort_code?.trim().replace(/-/g, ''),
          bank_country: process.env.REACT_APP_DEFAULT_COUNTRY_CODE
        }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: (data.wallet && data.wallet?.transfer_provider) || '',
  comment: trimTextContent(providerTableData?.comment) || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
