import FieldError from './FieldError';
import ObjectError from './ObjectError';
import UnknownError from './UnknownError';
import { logoutActiveSessionWithoutToken } from 'services/authUtils';
import { ROUTE_PATHS } from 'routes/constants';

async function errorMapper(error, errorFieldMapper = {}) {
  if (
    (error.message === 'Unauthorized' && error.status === 401) ||
    // eslint-disable-next-line max-len
    (['invalid_refresh_token', 'invalid_token', 'access_denied'].includes(error.response?.body?.error) &&
      window.location.pathname !== ROUTE_PATHS.LOGIN &&
      window.location.pathname !== ROUTE_PATHS.REGISTRATION)
  ) {
    logoutActiveSessionWithoutToken();
  }
  if (error?.response?.body?.fields) {
    const errorFields = error.response.body.fields.map(({ code, field }) => ({
      field: errorFieldMapper[field] ? errorFieldMapper[field] : field,
      code
    }));
    throw new FieldError({
      code: error.response.body.code,
      status: error.status,
      fields: errorFields
    });
  } else if (error?.response?.body?.args) {
    throw new ObjectError({
      status: error.status,
      code: error.response.body.code,
      args: error.response.body.args
    });
  } else {
    throw new UnknownError({
      status: error?.status,
      code: error?.response?.body?.code || error?.response?.body?.error,
      message: error?.response?.body?.message || error?.response?.body?.error_description || error.message,
      stack: error.stack
    });
  }
}

export default errorMapper;
