import { ReactTagManager } from 'react-gtm-ts';

const initializeAnalytics = () => {
  const gtmCode = process.env.REACT_APP_GTAG_ID;
  if (!gtmCode) return;
  ReactTagManager.init({
    code: gtmCode,
    debug: false, // Enable debug mode if needed
    performance: false // Optional: load GTM after user interaction
  });
};

export default initializeAnalytics;
