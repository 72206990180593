import { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PopUpSuccessScheme from '../common/PopUpScheme/PopUpSuccessScheme';
import ActivityLog from './ActivityLog/ActivityLog';
import Manage2FA from './Manage2FA';
import PasswordReset from './PasswordReset';
import UserDetails from './UserDetails';
import { ReactComponent as PasswordResetIcon } from 'assets/shield.svg';
import { ReactComponent as ActivityLogIcon } from 'assets/activity-log.svg';
import { ReactComponent as UserDetailIcon } from 'assets/user-detail-icon.svg';
import i18nContext from 'components/i18n-context';
import PopUpGoogle2FAScheme from 'components/common/PopUpScheme/PopUpGoogle2FAScheme';
import { PopUpGoogle2FADisableConfirmationScheme } from 'components/common/PopUpScheme/PopUpGoogle2FADisableConfirmationScheme';
import { runLogout } from 'services/requestAgent';
import { Container } from 'uikit/Container/Container';
import { IconButton } from 'uikit/IconButton/IconButton';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Settings.scss';

const Settings = ({ passwordRecoveryStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const [activeTab, setActiveTab] = useState('passwordReset');

  const disableGoogle2FA = () => userStore.disableGoogle2FA();

  return (
    <section className='right-section settings'>
      <Container header={i18n.getMessage('navigation.settings.text')}>
        <div className='settings-wrapper'>
          <div className='settings-container-left'>
            <IconButton
              className={`settings-icon ${activeTab === 'passwordReset' ? 'active' : ''}`}
              active={activeTab === 'passwordReset'}
              onClick={() => setActiveTab('passwordReset')}
              Icon={PasswordResetIcon}
              text={i18n.getMessage('settings.tab.label.passwordReset')}
            />
            <IconButton
              className={`settings-icon ${activeTab === 'manage2fa' ? 'active' : ''}`}
              active={activeTab === 'manage2fa'}
              onClick={() => setActiveTab('manage2fa')}
              Icon={PasswordResetIcon}
              text={i18n.getMessage('settings.tab.label.manage2fa')}
            />
            <IconButton
              className={`settings-icon ${activeTab === 'activityLog' ? 'active' : ''}`}
              active={activeTab === 'activityLog'}
              onClick={() => setActiveTab('activityLog')}
              Icon={ActivityLogIcon}
              text={i18n.getMessage('settings.tab.label.activityLog')}
            />
            <IconButton
              className={`settings-icon ${activeTab === 'userDetails' ? 'active' : ''}`}
              active={activeTab === 'userDetails'}
              onClick={() => setActiveTab('userDetails')}
              Icon={UserDetailIcon}
              text={i18n.getMessage('settings.tab.label.userDetails')}
            />
          </div>
          {activeTab === 'passwordReset' && <PasswordReset />}
          {activeTab === 'manage2fa' && <Manage2FA />}
          {activeTab === 'activityLog' && <ActivityLog />}
          {activeTab === 'userDetails' && <UserDetails />}
        </div>
      </Container>
      <PopUp
        className='google2fa'
        show={
          passwordRecoveryStore.isResetPasswordSuccess ||
          userStore.isShowGoogle2FaPopUp ||
          userStore.isGoogle2FaEnablingSuccess ||
          userStore.isGoogle2FaDisablingConfirmation ||
          userStore.isGoogle2FaDisablingSuccess
        }
      >
        {userStore.isShowGoogle2FaPopUp ? (
          <PopUpGoogle2FAScheme onClose={() => userStore.closeIsShowGoogle2FaPopUp()} />
        ) : userStore.isGoogle2FaEnablingSuccess ? (
          <PopUpSuccessScheme
            className='settings-google2fa-popup'
            title={i18n.getMessage('popUp.google2fa.enabled')}
            onClose={() => userStore.setIsGoogle2FaEnablingSuccess(false)}
          />
        ) : userStore.isGoogle2FaDisablingConfirmation ? (
          <PopUpGoogle2FADisableConfirmationScheme
            onConfirm={disableGoogle2FA}
            onClose={() => userStore.setIsGoogle2FaDisablingConfirmation(false)}
          />
        ) : userStore.isGoogle2FaDisablingSuccess ? (
          <PopUpSuccessScheme
            className='settings-google2fa-popup'
            title={i18n.getMessage('popUp.google2fa.disabled')}
            onClose={() => userStore.setIsGoogle2FaDisablingSuccess(false)}
          />
        ) : (
          <PopUpSuccessScheme message={i18n.getMessage('settings.passwordReset.success')} onClose={runLogout} />
        )}
      </PopUp>
    </section>
  );
};

Settings.propTypes = {
  userStore: MobXPropTypes.observableObject,
  passwordRecoveryStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  passwordRecoveryStore: stores.passwordRecoveryStore,
  isCustomerVerified: stores.userStore.isCustomerVerified
}))(observer(Settings));
