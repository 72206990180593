import { makeAutoObservable, runInAction } from 'mobx';
import { BACKEND_MODULE_TYPES, TWO_FACTOR_AUTH_TYPES } from 'components/common/constants';
import { setConfirmationActionType } from 'services/authUtils';
import {
  loginUser,
  loginPin,
  changePasswordUser,
  resendSecurityCode,
  getSmsCodeForce,
  getGoogle2FAForce
} from 'services/requestAgent';

class AuthStore {
  isLoading = false;
  isLoginSuccess = false;
  isUserAlreadyRegistered = false;
  isPinSuccess = false;
  isPasswordChanged = false;
  error = null;
  confirmationActionType = null;
  confirmationId = null;
  isGoogle2FAEnabled = false;
  phone = null;

  values = {
    phone: process.env.REACT_APP_DEFAULT_DIAL_CODE,
    username: '',
    password: '',
    code: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsLoginSuccess = (state) => (this.isLoginSuccess = state);

  setUsername(username) {
    this.values.username = username;
  }

  setPhone(phone) {
    this.values.phone = phone;
  }

  setPassword(password) {
    this.values.password = password;
  }

  setCode(code) {
    this.values.code = code;
  }

  setConfirmationId(id) {
    this.confirmationId = id;
  }

  setUserAuthorizationData(confirmationId, phone, twoFactorAuthType) {
    this.isUserAlreadyRegistered = true;
    this.isLoginSuccess = true;
    this.confirmationId = confirmationId;
    this.phone = phone;
    this.confirmationActionType = twoFactorAuthType;
    this.isGoogle2FAEnabled = twoFactorAuthType === TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH;
  }

  reset() {
    this.isLoading = false;
    this.isLoginSuccess = false;
    this.isUserAlreadyRegistered = false;
    this.isPinSuccess = false;
    this.confirmationActionType = null;
    this.confirmationId = null;
    this.isGoogle2FAEnabled = false;
    this.values.username = '';
    this.values.password = '';
    this.values.code = '';
  }

  async resendSecurityCode() {
    try {
      await resendSecurityCode(BACKEND_MODULE_TYPES.OAUTH, this.confirmationId);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    }
  }

  async sendAuthorizationData() {
    this.setIsLoading(true);
    try {
      const { confirmationId, twoFactorAuthType } = await loginUser({
        phone: this.values.phone,
        password: this.values.password
      });

      runInAction(() => {
        this.isLoginSuccess = true;
        this.isLoading = false;
        this.confirmationActionType = twoFactorAuthType;
        this.confirmationId = confirmationId;
        this.isGoogle2FAEnabled = twoFactorAuthType === TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH;
        this.phone = this.values.phone;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async forceSwitchToSmsConfirmation() {
    this.setIsLoading(true);
    try {
      const { confirmation_id: confirmationId, type } = await getSmsCodeForce();

      runInAction(() => {
        setConfirmationActionType(type);
        this.confirmationActionType = type;
        this.confirmationId = confirmationId;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async forceSwitchToGoogle2FAConfirmation() {
    this.setIsLoading(true);
    try {
      const { confirmation_id: confirmationId, type } = await getGoogle2FAForce();

      runInAction(() => {
        setConfirmationActionType(type);
        this.confirmationActionType = type;
        this.confirmationId = confirmationId;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async sendCode() {
    if (this.isLoading) {
      return;
    }

    this.setIsLoading(true);
    try {
      await loginPin(this.values.code, this.confirmationId);
      runInAction(() => {
        this.isPinSuccess = true;
        this.isUserAlreadyRegistered = false;
        this.isLoading = false;
        this.confirmationId = null;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordChange() {
    this.setIsLoading(true);
    try {
      await changePasswordUser(this.values.password);
      runInAction(() => {
        this.isPasswordChanged = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }
}

export default new AuthStore();
