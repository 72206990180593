import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardAccount from './components/CardAccount';
import CardsView from './components/CardsView';
import { NewCard } from '../NewCard';
import i18nContext from 'components/i18n-context';
// eslint-disable-next-line max-len
import AuthorizationListCompact from 'components/Cards/components/CardsAuthorizations/components/AuthorizationsListCompact';
import LatestTransactionsCompact from 'components/common/LatestTransactions/LatestTransactionsCompact';
import Loader from 'components/common/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import '../../Cards.scss';

const CardsDashboard = ({ cardsStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (cardsStore.isInitialized && location.pathname === ROUTE_PATHS.CARDS && cardsStore.cards.length === 0) {
      navigate(ROUTE_PATHS.CARDS_CREATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsStore.isInitialized]);

  const addNewCardHandler = (cardType) => {
    if (!cardType) {
      navigate(ROUTE_PATHS.CARDS_CREATE);
    } else {
      navigate(cardType);
    }
  };

  const openCardDetails = (cardId) => {
    navigate(cardId);
  };

  return (
    <div className={'cards-dashboard'}>
      {!cardsStore.isInitialized ? (
        <div className={'loader-container'}>
          <Loader />
        </div>
      ) : (
        <>
          {location.pathname === ROUTE_PATHS.CARDS && (
            <div className={'dashboard-wrapper'}>
              <CardAccount />
              <CardsView openCardDetails={openCardDetails} />
              <AuthorizationListCompact isCardsTransactions={true} />
              <LatestTransactionsCompact
                isCardsTransactions={true}
                customerNumber={cardsStore.selectedAccount?.wallet_number}
                accountNumber={cardsStore.selectedAccount?.wallet_number}
              />
            </div>
          )}
          {location.pathname === ROUTE_PATHS.CARDS_CREATE && (
            <div className={'dashboard-wrapper'}>
              <div className={'cards-wrapper'}>
                <NewCard
                  cardType={'virtual'}
                  title={i18n.getMessage('cards.newCard.virtual.title')}
                  description={i18n.getMessage('cards.newCard.virtual.description')}
                  handleClick={addNewCardHandler}
                  isDisabled={false}
                  isFirstCard={true}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

CardsDashboard.propTypes = {
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  cardsStore: stores.cardsStore
}))(observer(CardsDashboard));
