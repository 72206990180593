import { makeAutoObservable, runInAction } from 'mobx';
import {
  getUserInformation,
  getAccounts,
  getApplicationState,
  getTariffs,
  getUserInfoByCustomerNumber,
  enableGoogle2FARequest,
  disableGoogle2FARequest,
  confirmGoogle2FARequest,
  beginVerificationRequest
} from 'services/requestAgent';
import {
  setSelectedMultiAccount,
  getSelectedMultiAccount,
  setSecureUserPhone,
  setConfirmationActionType
} from 'services/authUtils';
import { CUSTOMER_TYPE, USER_CUSTOMER_STATUSES, TWO_FACTOR_AUTH_TYPES } from 'components/common/constants';
import { base64ToImageUrl } from 'components/utils';

class UserStore {
  isLoading = false;
  isInitialized = false;
  isVerificationLoading = false;
  error = null;
  userData = {};
  userAccounts = [];
  currentAccount = null;
  appFeatures = {};
  appConstants = {};
  isCustomerVerified = null;
  isUserDataFetched = false;
  enabledTariffs = [];
  currentCustomerPermissions = null;
  isShowGoogle2FaPopUp = false;
  isGoogle2FaEnablingSuccess = false;
  isGoogle2FaDisablingConfirmation = false;
  isGoogle2FaDisablingSuccess = false;
  isGoogle2FAEnabled = false;
  isGoogle2FALoading = false;
  googleQrCode = null;
  confirmationId = null;
  verificationAccessToken = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsGoogle2FALoading(status) {
    this.isGoogle2FALoading = status;
    this.error = null;
  }

  setIsVerificationLoading(status) {
    this.isVerificationLoading = status;
    this.error = null;
  }

  getVerifiedStatus(userData) {
    if (process.env.REACT_APP_KYC_MODE !== '/external') return true;

    if (userData.account?.type === CUSTOMER_TYPE.COMPANY || userData.account?.type === CUSTOMER_TYPE.INDIVIDUAL) {
      return userData.account?.status === USER_CUSTOMER_STATUSES.VERIFIED;
    }
  }

  setCurrentAccount(account) {
    this.currentAccount = account;
  }

  closeIsShowGoogle2FaPopUp() {
    this.isShowGoogle2FaPopUp = false;
    this.confirmationId = null;
    this.googleQrCode = null;
  }

  setIsGoogle2FaEnablingSuccess(status) {
    this.isGoogle2FaEnablingSuccess = status;
  }

  setIsGoogle2FaDisablingConfirmation(status) {
    this.isGoogle2FaDisablingConfirmation = status;
  }

  setIsGoogle2FaDisablingSuccess(status) {
    this.isGoogle2FaDisablingSuccess = status;
  }

  resetEnabledTariffs() {
    this.enabledTariffs = [];
  }

  updateRepresentativeData(updatedRepresentative, isRemove = false) {
    const existingRepresentative = this.userData.representatives?.find(({ id }) => id === updatedRepresentative.id);

    this.userData.representatives = existingRepresentative
      ? this.userData.representatives.reduce((result, representative) => {
          if (representative.id === updatedRepresentative.id) {
            if (isRemove) return result;
            result.push(updatedRepresentative);
            return result;
          }
          result.push(representative);

          return result;
        }, [])
      : [...this.userData.representatives, updatedRepresentative];
  }

  getCurrentCustomerPermissions(availableCustomers, customerNumber) {
    return (
      availableCustomers.find(
        (customer) =>
          customer.account_number === customerNumber || customer?.customer_account?.account_number === customerNumber
      )?.permissions || null
    );
  }

  hasPermissions = (expectedPermissions = []) => {
    const userPermissions = this.currentCustomerPermissions;
    // When we don't have permission in account object -> main account
    if (!userPermissions) {
      return true;
    }
    return !!userPermissions?.filter((permission) => expectedPermissions.includes(permission)).length;
  };

  changeCurrentUserAccount = async (customerNumber) => {
    runInAction(() => {
      this.verificationAccessToken = null;
      this.currentCustomerPermissions = this.getCurrentCustomerPermissions(
        this.userData.availableAccounts,
        customerNumber
      );
    });
    setSelectedMultiAccount(customerNumber);
    await this.loadUserData(customerNumber);
  };

  async getAppState() {
    try {
      this.setIsLoading(true);
      const data = await getApplicationState();
      runInAction(() => {
        this.appConstants = data.constants;
        this.appFeatures = data.features;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  getCurrentAccount(selectedSharedAccountData, ownUserAccounts) {
    if (selectedSharedAccountData) {
      return selectedSharedAccountData;
    }
    return ownUserAccounts.find(({ type }) => type === CUSTOMER_TYPE.COMPANY) || ownUserAccounts[0];
  }

  async loadUserData(customerNumber, isAfterSuccessfulRegistration) {
    this.setIsLoading(true);
    try {
      this.setIsLoading(true);
      let selectedSharedAccountData = null;
      const data = await getUserInformation();
      if (data?.user_info?.phone) {
        setSecureUserPhone(data?.user_info?.phone);
      }
      const selectedCustomerNumber = isAfterSuccessfulRegistration
        ? data.user_accounts[data.user_accounts.length - 1]?.account_number
        : customerNumber || getSelectedMultiAccount() || null;
      if (selectedCustomerNumber) {
        selectedSharedAccountData = await getUserInfoByCustomerNumber(selectedCustomerNumber);
      }
      // DTO changed for UI representation (past representation without multiaccounts)
      const userDataRepresentation = {
        account: this.getCurrentAccount(selectedSharedAccountData, data.user_accounts),
        representatives: data.representatives,
        availableAccounts: [...data.user_accounts, ...data.shared_accounts],
        ...data.user_info
      };

      const currentCustomerPermissions = this.getCurrentCustomerPermissions(
        userDataRepresentation.availableAccounts,
        selectedCustomerNumber
      );

      let accounts = [];
      // Move accounts logic to separate store!
      if (!userDataRepresentation?.account?.account_number) {
        // return (this.error = { code: 'ACCOUNT_NOT_REGISTERED' });
        this.error = { code: 'ACCOUNT_NOT_REGISTERED' };
      } else {
        accounts = await getAccounts(userDataRepresentation.account.account_number);
      }

      runInAction(() => {
        setConfirmationActionType(userDataRepresentation.two_factor_auth_type);
        this.userData = userDataRepresentation;
        this.isGoogle2FAEnabled = userDataRepresentation?.two_factor_auth_type === TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH;
        this.currentCustomerPermissions = currentCustomerPermissions;
        this.userAccounts = accounts;
        this.currentAccount = accounts.length > 0 ? accounts[0] : null;
        this.isCustomerVerified = this.getVerifiedStatus(userDataRepresentation);
        this.isUserDataFetched = true;
        this.isInitialized = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async loadEnabledTariffs(accountNumber, transactional) {
    try {
      // Move tariffs logic to separate store as well likely
      const data = await getTariffs(this.userData.account?.account_number, accountNumber, transactional);
      runInAction(() => {
        this.enabledTariffs = data;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }

  // Temporary solution for updating balances
  async getUserAccounts() {
    try {
      const accounts = await getAccounts(this.userData.account.account_number);
      runInAction(() => {
        this.userAccounts = accounts;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }

  isFeesEnabled() {
    return !!this.appFeatures.tariffsEnabled;
  }

  isCardsEnabled() {
    return !!this.appFeatures.cardsEnabled;
  }

  isRepresentativesEnabled() {
    return !!this.appFeatures.representativesEnabled;
  }

  getKycProvider() {
    return this.appFeatures.kycProvider;
  }

  getKybProvider() {
    return this.appFeatures.kybProvider;
  }

  async enableGoogle2FA() {
    this.setIsLoading(true);
    try {
      const { qr_code, confirmation_uid } = await enableGoogle2FARequest();

      runInAction(() => {
        this.googleQrCode = base64ToImageUrl(qr_code);
        this.confirmationId = confirmation_uid;
        this.isShowGoogle2FaPopUp = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async confirmGoogle2FAEnabling(code) {
    this.setIsGoogle2FALoading(true);
    try {
      await confirmGoogle2FARequest({ confirmation_id: this.confirmationId, code });

      runInAction(() => {
        setConfirmationActionType(TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH);
        this.confirmationId = null;
        this.googleQrCode = null;
        this.isGoogle2FaEnablingSuccess = true;
        this.isShowGoogle2FaPopUp = false;
        this.isGoogle2FAEnabled = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isGoogle2FALoading = false;
      });
    }
  }

  async disableGoogle2FA() {
    this.setIsGoogle2FALoading(true);
    try {
      await disableGoogle2FARequest();

      runInAction(() => {
        setConfirmationActionType(TWO_FACTOR_AUTH_TYPES.SMS);
        this.isGoogle2FAEnabled = false;
        this.isGoogle2FaDisablingConfirmation = false;
        this.isGoogle2FaDisablingSuccess = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isGoogle2FALoading = false;
      });
    }
  }

  async beginVerification(customerNumber, data) {
    this.setIsVerificationLoading(true);
    try {
      const { token: accessToken, kyc_status: kycStatus } = await beginVerificationRequest(customerNumber, data);

      runInAction(() => {
        this.verificationAccessToken = accessToken ? accessToken : null;

        if (this.userData?.account) {
          this.userData.account.kyc_level_name = data?.level_name;
          this.userData.account.kyc_status = kycStatus ? kycStatus : null;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isVerificationLoading = false;
      });
    }
  }
}

export default new UserStore();
