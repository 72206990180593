import moment from 'moment';
import { CARD_AUTHORIZATIONS_STATUS } from 'components/common/constants';

export const PREDEFINED_FILTERS = {
  TODAY: 'filterToday',
  YESTERDAY: 'filterYesterday',
  WEEK: 'filterThisWeek',
  LAST_WEEK: 'filterLastWeek',
  MONTH: 'filterThisMonth',
  LAST_MONTH: 'filterLastMonth'
};

export const getFromDate = (range) => {
  switch (range) {
    case PREDEFINED_FILTERS.TODAY:
      return {
        from: moment().toISOString(),
        to: moment().toISOString()
      };
    case PREDEFINED_FILTERS.YESTERDAY:
      return {
        from: moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days').toISOString(),
        to: moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days').toISOString()
      };
    case PREDEFINED_FILTERS.WEEK:
      return {
        from: moment().startOf('isoWeek').toISOString(),
        to: moment().toISOString()
      };
    case PREDEFINED_FILTERS.LAST_WEEK:
      return {
        from: moment().subtract(1, 'weeks').startOf('isoWeek').toISOString(),
        to: moment().subtract(1, 'weeks').endOf('isoWeek').toISOString()
      };
    case PREDEFINED_FILTERS.MONTH:
      return {
        from: moment().startOf('month').toISOString(),
        to: moment().endOf('month').toISOString()
      };
    case PREDEFINED_FILTERS.LAST_MONTH:
      return {
        from: moment().subtract(1, 'month').startOf('month').toISOString(),
        to: moment().subtract(1, 'month').endOf('month').toISOString()
      };
    default:
      return null;
  }
};

export const extractAccountNumbers = (accounts) => {
  return accounts.map((account) => account.wallet_number);
};
