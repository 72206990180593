import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n';
import { strToFloat } from 'services/utils';
import {
  customerInternalTransactionCheck,
  customerInternalTransactionCreate,
  customerInternalTransactionConfirm,
  resendSecurityCode
} from 'services/requestAgent';
import { updateConfirmationActionType } from '../services/authUtils';
import { BACKEND_MODULE_TYPES } from '../components/common/constants';

class BetweenAccountsStore {
  isInitialized = false;
  isLoading = false;
  isRepeatTransaction = false;
  isRedirectFromPaymentForm = false;
  isInternalTransactionChecked = false;
  isInternalTransactionCreated = false;
  isInternalTransferCompleted = false;
  isShowConfirmationPopUp = false;
  isShowSuccessPopUp = false;
  isCardAccountTopUp = false;
  isRedirectToCards = false;
  error = null;
  confirmationPopupError = null;
  accountFrom = null;
  accountTo = null;
  amount = null;
  description = null;
  confirmationId = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore() {
    this.isInitialized = false;
    this.isLoading = false;
    this.isRepeatTransaction = false;
    this.isRedirectFromPaymentForm = false;
    this.isInternalTransactionChecked = false;
    this.isInternalTransactionCreated = false;
    this.isInternalTransferCompleted = false;
    this.isShowConfirmationPopUp = false;
    this.isShowSuccessPopUp = false;
    this.isRedirectToCards = false;
    this.error = null;
    this.confirmationPopupError = null;
    this.accountFrom = null;
    this.accountTo = null;
    this.amount = null;
    this.description = null;
    this.confirmationId = null;
  }

  setError(error) {
    this.error = error;
  }

  setIsRepeatTransactionStatus(status) {
    this.isRepeatTransaction = status;
  }

  setIsRedirectFromPaymentFormStatus(status) {
    this.isRedirectFromPaymentForm = status;
  }

  setFromToAccounts(from, to) {
    this.accountFrom = from;
    this.accountTo = to;

    if (!from || !to) {
      this.error = i18n.getMessage('betweenAccounts.error.sameCurrencyAccounts');
    } else {
      this.error = null;
    }

    if (!this.isInitialized) {
      this.isInitialized = true;
    }
  }

  setAmount(amount) {
    this.amount = amount;
  }

  setDescription(description) {
    this.description = description;
  }

  setIsInternalTransactionCreated(status) {
    this.isInternalTransactionCreated = status;
  }

  setIsInternalTransferCompleted(status) {
    this.isInternalTransferCompleted = status;
  }

  setIsCardAccountTopUp(status) {
    this.isCardAccountTopUp = status;
  }

  setIsRedirectToCards(status) {
    this.isRedirectToCards = status;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  async checkInternalTransaction(customerNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      await customerInternalTransactionCheck(customerNumber, {
        sender_wallet: this.accountFrom.wallet_number,
        recipient_wallet: this.accountTo.wallet_number,
        amount: strToFloat(this.amount),
        comment: this.description
      });

      runInAction(() => {
        this.isInternalTransactionChecked = true;
      });
    } catch (err) {
      if (err.code === 'EXCHANGE_RATE_MIN_VALUE') {
        let newError = {};
        newError.fields = {
          amount: err.code
        };
        runInAction(() => {
          this.error = newError;
          this.isInternalTransactionChecked = false;
        });
        return;
      }
      runInAction(() => {
        this.error = err;
        this.isInternalTransactionChecked = false;
      });
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createInternalTransaction(customerNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { confirmation_id: confirmationId, confirmation_type: confirmationType } =
        await customerInternalTransactionCreate(customerNumber, {
          sender_wallet: this.accountFrom.wallet_number,
          recipient_wallet: this.accountTo.wallet_number,
          amount: strToFloat(this.amount),
          comment: this.description
        });

      updateConfirmationActionType(confirmationType);

      runInAction(() => {
        this.isInternalTransactionCreated = true;
        this.confirmationId = confirmationId;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isInternalTransactionCreated = false;
      });
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async resendTransactionSecurityCode() {
    try {
      await resendSecurityCode(BACKEND_MODULE_TYPES.PAYMENT, this.confirmationId);
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async verifyInternalTransaction(customerNumber, securityCode) {
    try {
      runInAction(() => {
        this.isLoading = false;
      });

      await customerInternalTransactionConfirm(customerNumber, {
        code: securityCode,
        confirmation_id: this.confirmationId
      });

      runInAction(() => {
        this.isShowSuccessPopUp = true;
        this.isInternalTransferCompleted = true;
      });
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default new BetweenAccountsStore();
