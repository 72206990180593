import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LatestPaymentObligationItem } from './LatestPaymentObligationItem';
import { LatestPaymentObligationItemLoader } from './LatestPaymentObligationItemLoader';
import i18nContext from 'components/i18n-context';
import Loader from 'components/common/Loader';
import { PAYMENT_OBLIGATIONS_TABLE_HEADER } from 'components/common/constants';
import PopUpPaymentObligationScheme from 'components/common/PopUpScheme/PopUpPaymentObligationScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { PopUp } from 'uikit/PopUp/PopUp';
import './LatestPaymentObligations.scss';

const paymentObligationsTableMaxHeight = 280;

const LatestPaymentObligationsList = ({ obligations, isLoading }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const [popUpState, setPopUpState] = useState({ open: false, obligation: {} });
  const tableWrapperRef = useRef(null);

  useEffect(() => {
    if (tableWrapperRef.current) {
      if (tableWrapperRef.current.scrollHeight > paymentObligationsTableMaxHeight) {
        tableWrapperRef.current.style.overflowY = 'auto';
      } else {
        tableWrapperRef.current.style.overflowY = 'hidden';
      }
    }
  }, [tableWrapperRef.current]);

  const showPopup = (obligation) => {
    setPopUpState({ open: true, obligation: obligation });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, obligation: {} });
  };

  const renderPopUp = () => {
    return (
      <>
        <PopUp className='transaction-info' show={popUpState.open} onClose={closePopUp}>
          <PopUpPaymentObligationScheme selectedObligation={popUpState.obligation} onClose={closePopUp} />
        </PopUp>
      </>
    );
  };

  if (isMobile) {
    return (
      <div className={'obligations-mobile-wrapper'}>
        {isLoading
          ? [...Array(obligations.length).keys()].map((loadObligation) => (
              <LatestPaymentObligationItemLoader isMobile={isMobile} key={loadObligation} />
            ))
          : obligations.map((obligation) => (
              <LatestPaymentObligationItem
                isMobile={isMobile}
                key={obligation.obligation_id}
                obligation={obligation}
                onInfoClick={() => showPopup(obligation)}
              />
            ))}
        {renderPopUp()}
      </div>
    );
  }

  return (
    <>
      <div className={'obligations-table-wrapper'} ref={tableWrapperRef}>
        <table className='obligations-wrapper'>
          <thead>
            <tr>
              {isLoading ? (
                <th colSpan={5} className={'obligation-loader-wrapper'}>
                  <Loader className={'obligation-loader'} />
                </th>
              ) : (
                PAYMENT_OBLIGATIONS_TABLE_HEADER.map((header) => (
                  <th className={header} key={header}>
                    {header && i18n.getMessage(`latestPaymentObligations.header.${header}`)}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? [...Array(obligations.length).keys()].map((loadObligation) => (
                  <LatestPaymentObligationItemLoader key={loadObligation} />
                ))
              : obligations.map((obligation) => (
                  <LatestPaymentObligationItem
                    key={obligation.obligation_id}
                    obligation={obligation}
                    onInfoClick={() => showPopup(obligation)}
                  />
                ))}
          </tbody>
        </table>
      </div>
      {renderPopUp()}
    </>
  );
};

LatestPaymentObligationsList.propTypes = {
  obligations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default LatestPaymentObligationsList;
