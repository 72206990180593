import { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import i18nContext from 'components/i18n-context';
import {
  ACCOUNT_PROPERTIES,
  ACCOUNT_TOOLTIP_TEXT,
  CUSTOMER_PERMISSIONS,
  PAYMENT_PROVIDERS,
  WALLESTER_ACCOUNT_TOOLTIP_TEXT
} from 'components/common/constants';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import ComplianceRequests from 'components/common/LatestTransactions/ComplianceRequests';
import LatestPaymentObligations from 'components/common/LatestPaymentObligations/LatestPaymentObligations';
import LatestTransactionsCompact from 'components/common/LatestTransactions/LatestTransactionsCompact';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import PaymentForm from 'components/common/PaymentForm';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import Verification from 'components/Verification/Verification';
import { getAccountTitle } from 'components/utils';
import { ROUTE_PATHS } from 'routes/constants';
import { Card } from 'uikit/Card/Card';
import { PopUp } from 'uikit/PopUp/PopUp';
import './DashBoard.scss';

const DashBoard = ({ userStore, paymentStore, transactionStore, cardsStore, paymentObligationsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  useEffect(() => {
    if (userStore.userData.account?.account_number) {
      transactionStore.getComplianceRequestList(userStore.userData.account?.account_number);
      paymentObligationsStore.getPaymentObligationsList(userStore.userData.account?.account_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.userData.account?.account_number]);

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (account) => {
    transactionStore.setAccount(account);
    navigate(ROUTE_PATHS.TRANSACTIONS);
  };

  const goToPayment = (account) => {
    paymentStore.setSelectedAccount(account);
    navigate(ROUTE_PATHS.NEW_PAYMENT);
  };

  const goToCards = (account) => {
    cardsStore.setSelectedAccount(account);
    navigate(ROUTE_PATHS.CARDS);
  };

  if (userStore.userData?.account && userStore.isCustomerVerified !== null && !userStore.isCustomerVerified) {
    return (
      <section className='right-section verifications'>
        <Verification />
      </section>
    );
  }

  if (!userStore.userData?.account) {
    return <NoOutlet error={userStore.error} />;
  }

  const isShowPaymentByPermission = userStore?.currentCustomerPermissions
    ? userStore?.currentCustomerPermissions?.find(
        (permission) => permission === CUSTOMER_PERMISSIONS.CREATE_TRANSACTION
      )
    : true;

  const checkWallesterProvider = (account) => {
    return account.transfer_provider === PAYMENT_PROVIDERS.WALLESTER;
  };

  return (
    <>
      {userStore.userAccounts.length === 0 ? (
        <ContactUs
          icon={<SupportIcon />}
          header={i18n.getMessage('dashboard.noAccounts.title')}
          description={i18n.getMessage('dashboard.noAccounts.description')}
          button={i18n.getMessage('dashboard.noAccounts.button')}
        />
      ) : (
        <section className='right-section dashboard'>
          <div className='card-wrapper'>
            {userStore.userAccounts?.map((account) => {
              const isWallesterWallet = checkWallesterProvider(account);

              return (
                <Card
                  key={account[ACCOUNT_PROPERTIES.ACCOUNT_NUMBER]}
                  type={'card'}
                  account={getAccountTitle(account)}
                  balance={account[ACCOUNT_PROPERTIES.AVAILABLE]}
                  currency={account[ACCOUNT_PROPERTIES.CURRENCY]}
                  reserved={account[ACCOUNT_PROPERTIES.ON_HOLD]}
                  buttonText={'Review account'}
                  onExclaimPointClick={() => showPopup(account)}
                  onTransactionClick={() => goToTransaction(account)}
                  onPaymentClick={isWallesterWallet ? () => goToCards(account) : () => goToPayment(account)}
                  tooltipText={isWallesterWallet ? WALLESTER_ACCOUNT_TOOLTIP_TEXT : ACCOUNT_TOOLTIP_TEXT}
                />
              );
            })}
          </div>
          <PopUp className='account-info' show={popUpState.open} onClose={closePopUp}>
            <PopUpScheme activeAccount={popUpState.account} onClose={closePopUp} />
          </PopUp>
          {process.env.REACT_APP_IS_PAYFORM_ON_MAIN === 'true' && isShowPaymentByPermission && <PaymentForm />}
          {transactionStore.complianceRequestList.length > 0 && <ComplianceRequests />}
          {paymentObligationsStore.obligationList.length > 0 && <LatestPaymentObligations />}
          <LatestTransactionsCompact />
        </section>
      )}
    </>
  );
};

DashBoard.propTypes = {
  userStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  transactionStore: MobXPropTypes.observableObject,
  cardsStore: MobXPropTypes.observableObject,
  paymentObligationsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  paymentStore: stores.paymentStore,
  transactionStore: stores.transactionsStore,
  cardsStore: stores.cardsStore,
  paymentObligationsStore: stores.paymentObligationsStore
}))(observer(DashBoard));
