import PropTypes from 'prop-types';
import { classNames } from '../utils';
import { ReactComponent as ExclamationIcon } from './warning.svg';
import './Alert.scss';

const Alert = ({ message, type, className }) => {
  const alertClasses = classNames({
    'alert-message': true,
    [type]: true,
    [className]: true
  });

  if (!message) return null;

  return (
    <div className={alertClasses}>
      <ExclamationIcon className={'alert-icon'} />
      <p className={'alert-text'}>{message}</p>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string
};

export default Alert;
