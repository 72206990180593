import { useContext, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';

import i18nContext from 'components/i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import { ReactComponent as EmailIcon } from 'assets/email-icon.svg';
import { SecurityInput } from 'uikit/SecurityInput/SecurityInput';
import Alert from 'uikit/Alert/Alert';
import { getErrorMessageForAlert } from 'services/utils';

import '../RegistrationStyles.scss';
import { CountDown } from '../../common/CountDown/CountDown';

const EXCEEDED_ATTEMPTS_ERROR = 'EMAIL_CONFIRMATION_EXCEEDED_ATTEMPTS';
const RESEND_TIMEOUT = 60;

const VerificationCode = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);
  const [sentMessage, setSentMessage] = useState('');
  const [showCountDown, setShowCountDown] = useState(false);

  const handleSubmit = (verificationCode) => {
    registrationStore.setVerificationCode(verificationCode);

    registrationStore.sendVerificationCode();
    setSentMessage('');
  };

  const handleResendCode = async () => {
    setShowCountDown(true);
    await registrationStore.resendVerificationCode();
    setSentMessage(i18n.getMessage('pin.alert.sent'));
  };

  return (
    <div className='registration-form' id='form1'>
      <h1 className='registration-title verification-title'> {i18n.getMessage('register.title.email')} </h1>
      <EmailIcon className='svg-email-icon email-confirmation-icon' />
      <h1 className='security-code-title'>{i18n.getMessage('register.label.emailVerification')}</h1>
      <Alert
        type={registrationStore.error ? 'warning' : 'success'}
        message={(registrationStore.error && getErrorMessageForAlert(i18n, registrationStore.error)) || sentMessage}
      />
      <div className='registration-description'>
        <ComplexTranslate
          text={'register.text.verificationCode'}
          interpolation={{
            email: <strong>{registrationStore.generalFields.email}</strong>
          }}
        />
      </div>
      <SecurityInput
        type={'email'}
        values={registrationStore.verificationCode}
        error={!!registrationStore?.error}
        disabled={registrationStore?.error?.code === EXCEEDED_ATTEMPTS_ERROR}
        onComplete={handleSubmit}
      />
      <div className='registration-description dark'>
        {!showCountDown ? (
          <ComplexTranslate
            text={'register.description.verificationCode'}
            interpolation={{
              changeEmail: (
                <button
                  className='unset link-normal'
                  onClick={() => {
                    registrationStore.changeConfirmationEmail();
                  }}
                >
                  {i18n.getMessage('register.text.verificationCode.changeEmail')}
                </button>
              )
              // Postponed for next release
              // resendCode: <button
              //   className="unset link-normal"
              //   onClick={ (e) => handleResendCode(e) }>
              //   { i18n.getMessage('sms.resendCode') }
              // </button>
            }}
          />
        ) : (
          <p className='resend-info'>
            {i18n.getMessage('sms.resentMessage') + ' '}
            <CountDown onEnd={() => setShowCountDown(false)} duration={RESEND_TIMEOUT} />
          </p>
        )}
      </div>
    </div>
  );
};

VerificationCode.propTypes = {
  registrationStore: MobXPropTypes.observableObject
};

export default inject('registrationStore')(observer(VerificationCode));
