import { useState, useEffect } from 'react';

const useDeviceCheck = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(null);

  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent;
      const mobileKeywords = [
        'Android',
        'webOS',
        'iPhone',
        'iOS',
        'iPad',
        'iPod',
        'BlackBerry',
        'Windows Phone',
        'KaiOS',
        'Tizen',
        'Firefox OS'
      ];

      const isMobileDevice = mobileKeywords.some((keyword) => userAgent.includes(keyword));

      setIsMobileDevice(isMobileDevice);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobileDevice;
};

export default useDeviceCheck;
