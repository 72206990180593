import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_PROPERTIES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue } from 'services/utils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import '../../../Cards.scss';
import { ROUTE_PATHS } from '../../../../../routes/constants';

const CardAccount = ({ betweenAccountsStore, cardsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  const handleSelectAccount = (name, value) => {
    const selectedAccount = cardsStore.accounts.find((account) => account?.wallet_number === value);
    cardsStore.setSelectedAccount(selectedAccount);
  };

  const openTopUp = () => {
    betweenAccountsStore.setIsCardAccountTopUp(true);
    cardsStore.setTopUpAccount(cardsStore.selectedAccount);
    navigate(ROUTE_PATHS.BETWEEN_ACCOUNTS);
  };

  const accountsOptions = cardsStore?.accounts.map((account) => {
    return {
      key: account.wallet_number,
      value: account.wallet_number
    };
  });

  return (
    <Container
      className={'container-cards'}
      header={i18n.getMessage('cards.container.cardAccount')}
      isMaxWidthUnset={true}
    >
      <div className='card-account-wrapper'>
        <div className='account-sales-department-wrapper'>
          <span className='account-sales-department-title'>
            {i18n.getMessage('cards.cardAccount.label.accountNumber')}
          </span>
          <InputDropDown
            className='account-number-selector'
            name='card_account'
            value={cardsStore.selectedAccount?.wallet_number}
            onChange={handleSelectAccount}
            options={accountsOptions}
            isMulti={false}
          />
        </div>
        <div className='account-balance-wrapper'>
          <span className='account-balance-title'>{i18n.getMessage('cards.cardAccount.label.availableBalance')}</span>
          <div className='account-balance-data-wrapper'>
            <span className='account-balance-currency'>{cardsStore.selectedAccount[ACCOUNT_PROPERTIES.CURRENCY]}</span>
            <span className='account-balance-value'>
              {amountFormattedValue(cardsStore.selectedAccount[ACCOUNT_PROPERTIES.AVAILABLE])}
            </span>
          </div>
        </div>
        <Button className='top-up-button' size='large' type='outline' onClick={openTopUp}>
          {i18n.getMessage('cards.cardAccount.button.topUp')}
        </Button>
      </div>
    </Container>
  );
};

CardAccount.propTypes = {
  betweenAccountsStore: MobXPropTypes.observableObject,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  betweenAccountsStore: stores.betweenAccountsStore,
  cardsStore: stores.cardsStore
}))(observer(CardAccount));
