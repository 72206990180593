import { makeAutoObservable, runInAction } from 'mobx';
import { SESSION_STORAGE_CONSTANTS } from 'components/common/constants';
import {
  changePasswordUser,
  generatePasswordRecoverySecurityCode,
  fetchRecoveryLink,
  passwordReset,
  passwordRecoveryPhone,
  passwordRecoveryPhoneConfirm,
  passwordRecoveryPhoneResend,
  passwordRecoveryEmailCheck,
  passwordRecoveryEmailConfirm,
  passwordRecoveryEmailResend
} from 'services/requestAgent';
import { logoutActiveSessionWithoutToken } from 'services/authUtils';

class PasswordRecoveryStore {
  isLoading = false;
  isLoginSuccess = false;
  isResetPasswordSuccess = false;
  isShowingConfirmationPopUp = false;
  recoveryLinkError = null;
  error = null;
  confirmationActionType = null;
  phone = null;
  recoveryStatus = null;
  isSecurityCodeGenerated = false;
  responseId = null;

  values = {
    username: '',
    password: '',
    code: ''
  };

  generalFields = {
    phoneNumber: process.env.REACT_APP_DEFAULT_DIAL_CODE,
    sms: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsShowingConfirmationPopUp(status) {
    this.isShowingConfirmationPopUp = status;
  }

  setUsername(username) {
    this.values.username = username;
  }

  setPassword(password) {
    this.values.password = password;
  }

  setCode(code) {
    this.values.code = code;
  }

  setRecoveryStatus(status) {
    this.recoveryStatus = status;
  }

  setResponseID(response_id) {
    this.responseId = response_id;
  }

  setPhoneNumber(phoneNumber) {
    this.generalFields.phoneNumber = phoneNumber;
  }

  reset() {
    this.isLoading = false;
    this.isLoginSuccess = false;
    this.isPinSuccess = false;
    this.isResetPasswordSuccess = false;
    this.isShowingConfirmationPopUp = false;
    this.values.username = '';
    this.values.password = '';
    this.values.code = '';
    this.recoveryLinkError = '';
    this.generalFields.phoneNumber = '';
  }

  async getPasswordRecoveryLinkData(tokenId) {
    this.setIsLoading(true);
    try {
      const { step, response_id, phone } = await fetchRecoveryLink(tokenId);

      runInAction(() => {
        this.recoveryStatus = step;
        this.responseId = response_id;
        if (phone) this.generalFields.phoneNumber = phone;
      });
    } catch (err) {
      runInAction(() => {
        this.recoveryLinkError = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async generateSecurityCode() {
    this.setIsLoading(true);
    try {
      const { response_id } = await generatePasswordRecoverySecurityCode(this.responseId);
      runInAction(() => {
        this.isSecurityCodeGenerated = true;
        this.responseId = response_id;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordChange() {
    this.setIsLoading(true);
    try {
      await changePasswordUser({
        password: this.values.password,
        response_id: this.responseId
      });
      runInAction(() => {
        this.isLoading = false;
      });
      window.sessionStorage.setItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED, 'passwordRecovery');
      logoutActiveSessionWithoutToken();
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  async sendPasswordResetRequest(data) {
    this.setIsLoading(true);
    this.setIsShowingConfirmationPopUp(false);
    try {
      await passwordReset(data);
      runInAction(() => {
        this.isResetPasswordSuccess = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async sendPhoneNumber(phoneNumber) {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryPhone(phoneNumber);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendPhoneCode() {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryPhoneConfirm(this.responseId, this.values.code);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
      this.setCode('');
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async resendPhoneCode() {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryPhoneResend(this.responseId);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendEmail() {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryEmailCheck(this.responseId, this.values.username);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendEmailCode() {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryEmailConfirm(this.responseId, this.values.code);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async resendEmailCode() {
    try {
      this.setIsLoading(true);
      const status = await passwordRecoveryEmailResend(this.responseId);
      this.setRecoveryStatus(status?.step);
      this.setResponseID(status.response_id);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }
}

export default new PasswordRecoveryStore();
