import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/pop-up-close-icon.svg';
import { ReactComponent as CardInfoCopyIcon } from 'assets/card-info-copy-icon.svg';
import i18nContext from 'components/i18n-context';
import { chunkCardNumber } from 'components/Cards/utils';
import Loader from 'components/common/Loader';
import Button from 'uikit/Button/Button';
import { IconButton } from 'uikit/IconButton/IconButton';
import './PopUpScheme.scss';

const TOOLTIP_FIELDS = {
  CARDHOLDER_NAME: 'cardholderName',
  CARD_NUMBER: 'cardNumber',
  EXPIRATION_DATE: 'expirationDate',
  CVV: 'cvv'
};

export const PopUpCardDetailsScheme = ({ cardholderName, cardNumber, expirationDate, cvv, onClose }) => {
  const i18n = useContext(i18nContext);
  const [isCardDataUploaded, setIsCardDataUploaded] = useState(false);
  const [isCardDataCopied, setIsCardDataCopied] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null); // State for managing active tooltip
  const tooltipTimeoutRef = useRef(null); // Ref to store timeout references

  // Set the card data as uploaded if both card number and CVV are present
  useEffect(() => {
    if (cardNumber && cvv) {
      setIsCardDataUploaded(true);
    }
  }, [cardNumber, cvv]);

  // Function to copy card data and show tooltip for 3 seconds
  const handleClickCopyIconButton = (copiedData, fieldName) => {
    navigator.clipboard.writeText(copiedData);

    // Clear any existing timeout
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }

    setActiveTooltip(fieldName); // Activate the tooltip for the selected field

    // Set a new timeout to deactivate the tooltip after 3 seconds
    tooltipTimeoutRef.current = setTimeout(() => {
      setActiveTooltip(null);
    }, 3000);
  };

  // Function to copy all card data to clipboard
  const copyAllData = async () => {
    const formattedData = `Card number\n${cardNumber}\nExpiration date\n${expirationDate}\nCVV\n${cvv}`;
    await navigator.clipboard.writeText(formattedData);

    setIsCardDataCopied(true); // Disabled the button

    // Clear any existing timeout and deactivate copy icon button tooltip
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
      setActiveTooltip(null);
    }

    // Re-enable the button and reset the title after 3 seconds
    setTimeout(() => {
      setIsCardDataCopied(false); // Enable the button again
    }, 3000);
  };

  return (
    <div className='scheme-wrapper'>
      <div className='scheme-header'>
        <p className='scheme-header-text'>{i18n.getMessage('cards.popUp.cardDetails.title')}</p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>
      <div className='scheme-content-wrapper'>
        {!isCardDataUploaded ? (
          <Loader />
        ) : (
          <>
            {cardholderName && (
              <div className='scheme-card-info-block'>
                <div className='scheme-card-info'>
                  <p className='scheme-card-info-label'>
                    {i18n.getMessage('cards.popUp.cardDetails.block.cardholderName')}
                  </p>
                  <p className='scheme-card-info-value'>{cardholderName}</p>
                </div>
                <IconButton
                  className='scheme-card-info-copy-button'
                  onClick={() => handleClickCopyIconButton(cardholderName, TOOLTIP_FIELDS.CARDHOLDER_NAME)}
                  Icon={CardInfoCopyIcon}
                  tooltipText={i18n.getMessage('cards.popUp.cardDetails.tooltipText.copied')}
                  isTooltipActive={activeTooltip === TOOLTIP_FIELDS.CARDHOLDER_NAME}
                  isDisabled={activeTooltip === TOOLTIP_FIELDS.CARDHOLDER_NAME}
                />
              </div>
            )}
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>{i18n.getMessage('cards.popUp.cardDetails.block.cardNumber')}</p>
                <p className='scheme-card-info-value'>{chunkCardNumber(cardNumber)}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => handleClickCopyIconButton(cardNumber, TOOLTIP_FIELDS.CARD_NUMBER)}
                Icon={CardInfoCopyIcon}
                tooltipText={i18n.getMessage('cards.popUp.cardDetails.tooltipText.copied')}
                isTooltipActive={activeTooltip === TOOLTIP_FIELDS.CARD_NUMBER}
                isDisabled={activeTooltip === TOOLTIP_FIELDS.CARD_NUMBER}
              />
            </div>
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>
                  {i18n.getMessage('cards.popUp.cardDetails.block.expirationDate')}
                </p>
                <p className='scheme-card-info-value'>{expirationDate}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => handleClickCopyIconButton(expirationDate, TOOLTIP_FIELDS.EXPIRATION_DATE)}
                Icon={CardInfoCopyIcon}
                tooltipText={i18n.getMessage('cards.popUp.cardDetails.tooltipText.copied')}
                isTooltipActive={activeTooltip === TOOLTIP_FIELDS.EXPIRATION_DATE}
                isDisabled={activeTooltip === TOOLTIP_FIELDS.EXPIRATION_DATE}
              />
            </div>
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>{i18n.getMessage('cards.popUp.cardDetails.block.cvv')}</p>
                <p className='scheme-card-info-value'>{cvv}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => handleClickCopyIconButton(cvv, TOOLTIP_FIELDS.CVV)}
                Icon={CardInfoCopyIcon}
                tooltipText={i18n.getMessage('cards.popUp.cardDetails.tooltipText.copied')}
                isTooltipActive={activeTooltip === TOOLTIP_FIELDS.CVV}
                isDisabled={activeTooltip === TOOLTIP_FIELDS.CVV}
              />
            </div>
          </>
        )}
      </div>
      <Button
        className='scheme-button'
        fullWidth={true}
        onClick={copyAllData}
        isDisabled={!isCardDataUploaded || isCardDataCopied}
      >
        {isCardDataCopied
          ? i18n.getMessage('cards.details.button.copiedToClipboard')
          : i18n.getMessage('cards.details.button.copyAll')}
      </Button>
    </div>
  );
};

PopUpCardDetailsScheme.propTypes = {
  cardholderName: PropTypes.string,
  cardNumber: PropTypes.string,
  expirationDate: PropTypes.string,
  cvv: PropTypes.string,
  onClose: PropTypes.func.isRequired
};
