import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { PREDEFINED_FILTERS, getFromDate, isFilterDisabled } from './utils';

const tabWidth = 150;

const TransactionRangeFilter = ({ onChange, value, minDate }) => {
  const i18n = useContext(i18nContext);
  const tabsContainerRef = useRef(null);

  useEffect(() => {
    if (minDate) {
      onChange(value, getFromDate(value, minDate));
    }
  }, [minDate]);

  const handleTabClick = (filterName) => {
    const tabElement = document.getElementById(filterName);
    if (tabElement) {
      const containerWidth = tabsContainerRef.current.offsetWidth;
      const scrollLeft = tabElement.offsetLeft + tabWidth - containerWidth;
      const isLeftEdge = tabElement.offsetLeft === 0;
      const isRightEdge = tabElement.offsetLeft + tabWidth === containerWidth;
      tabsContainerRef.current.scrollLeft = isRightEdge ? Math.max(scrollLeft, 0) : isLeftEdge ? 0 : scrollLeft;
    }
    onChange(filterName, getFromDate(filterName, minDate));
  };

  return (
    <div className='tabs-wrapper' ref={tabsContainerRef}>
      {Object.values(PREDEFINED_FILTERS).map((filterName) => {
        const isDisabled = isFilterDisabled(filterName, minDate);

        return (
          <button
            type='button'
            className={`filter-tab ${value === filterName ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
            onClick={() => handleTabClick(filterName)}
            key={filterName}
            id={filterName}
            disabled={isDisabled}
          >
            {i18n.getMessage(`transaction.rangeFilter.${filterName}`)}
          </button>
        );
      })}
    </div>
  );
};

TransactionRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  minDate: PropTypes.instanceOf(Date)
};

export default TransactionRangeFilter;
