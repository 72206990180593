/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import PersonalRegistration from './Personal';
import BusinessRegistration from './Business';
import VerificationCode from './VerificationCode';
import {
  CUSTOMER_TYPE,
  REGISTRATION_CUSTOMER_TYPE,
  REGISTRATION_STATUSES,
  REGISTRATION_STEPS
} from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { RadioGroup } from 'uikit/RadioGroup/RadioGroup';
import Alert from 'uikit/Alert/Alert';
import { getErrorMessageForAlert } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import '../RegistrationStyles.scss';

const Registration = ({ registrationStore, userStore }) => {
  const [isDisabledPersonalTab, setIsDisabledPersonalTab] = useState(
    userStore?.userData?.availableAccounts?.some((acc) => acc.type === CUSTOMER_TYPE.INDIVIDUAL)
  );
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (registrationStore.currentStep !== REGISTRATION_STEPS.CUSTOMER_CREATION) {
      registrationStore.setCurrentRegistrationStep(REGISTRATION_STEPS.CUSTOMER_CREATION);
    }
  }, []);

  useEffect(() => {
    registrationStore.clearError();
    if (registrationStore.registrationStatus === REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED) {
      navigate(ROUTE_PATHS.REGISTRATION_SUCCESS);
    }
  }, [registrationStore.registrationStatus]);

  const handleChangeRegistrationType = (type) => {
    registrationStore.setRegistrationType(type);
  };

  useEffect(() => {
    if (userStore?.userData?.availableAccounts?.some((acc) => acc.type === CUSTOMER_TYPE.INDIVIDUAL) === true) {
      setIsDisabledPersonalTab(true);

      if (registrationStore.registrationType === REGISTRATION_CUSTOMER_TYPE.PERSONAL) {
        handleChangeRegistrationType(REGISTRATION_CUSTOMER_TYPE.BUSINESS);
      }
    } else {
      setIsDisabledPersonalTab(false);
    }
  }, [userStore?.userData?.availableAccounts]);

  return registrationStore.registrationStatus === REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION ? (
    <VerificationCode />
  ) : (
    <div className='registration-form' id='form1'>
      <h1 className='registration-title'>
        {' '}
        {i18n.getMessage(
          registrationStore.registrationType === REGISTRATION_CUSTOMER_TYPE.PERSONAL
            ? 'register.title.createIndividualCustomer'
            : 'register.title.createBusinessCustomer'
        )}{' '}
      </h1>
      <RadioGroup
        classes={'registration-radio-group'}
        radioProps={[
          {
            label: i18n.getMessage('account.label.personal'),
            value: i18n.getMessage('account.label.personal'),
            checked: registrationStore.registrationType === REGISTRATION_CUSTOMER_TYPE.PERSONAL
          },
          {
            label: i18n.getMessage('account.label.personal'),
            value: i18n.getMessage('account.label.business'),
            checked: registrationStore.registrationType === REGISTRATION_CUSTOMER_TYPE.BUSINESS
          }
        ]}
        checkedValue={registrationStore.registrationType}
        disableLeft={isDisabledPersonalTab}
        onChange={handleChangeRegistrationType}
      />
      <Alert
        type={'warning'}
        message={registrationStore?.error && getErrorMessageForAlert(i18n, registrationStore.error)}
      />
      <div className='form-data'>
        {registrationStore.registrationType === REGISTRATION_CUSTOMER_TYPE.PERSONAL ? (
          <PersonalRegistration />
        ) : (
          <BusinessRegistration />
        )}
      </div>
    </div>
  );
};

Registration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  registrationStore: stores.registrationStore
}))(observer(Registration));
