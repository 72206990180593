import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CardReplaceIcon } from 'assets/card-replace.svg';
import { CARD_ACTIONS } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';

export const PopUpReplaceCardConfirmationScheme = ({ onClose, onConfirm }) => {
  const i18n = useContext(i18nContext);

  return (
    <div>
      <div className={'scheme-wrapper pop-up-logOut'}>
        <CardReplaceIcon className={'replace-icon'} />
        <h2 className={'scheme-success-header'}>{i18n.getMessage('cards.popUp.replaceCard.confirmation.title')}</h2>
        <p className={'scheme-success-message'}>
          {i18n.getMessage('cards.popUp.replaceCard.confirmation.description')}
        </p>
        <div className={'scheme-wrapper-buttons'}>
          <Button className={'scheme-button'} size={'large'} type={'outline'} onClick={onConfirm}>
            {i18n.getMessage('cards.popUp.replaceCard.confirmation.btn.confirm')}
          </Button>
          <Button className={'scheme-error-button scheme-button'} size={'large'} onClick={() => onClose()}>
            {i18n.getMessage('cards.popUp.replaceCard.confirmation.btn.back')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopUpReplaceCardConfirmationScheme.propTypes = {
  type: PropTypes.oneOf([CARD_ACTIONS.REPLACE, CARD_ACTIONS.SUCCESS]).isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
