import React from 'react';
import { Stepper } from 'react-form-stepper';
import PropTypes from 'prop-types';
import { classNames } from 'uikit/utils';
import './CustomFormStepper.scss';

const CustomFormStepper = ({ steps, currentStep, className }) => {
  const formStepperClasses = classNames({
    'form-stepper-wrapper': true,
    [className]: true
  });

  return (
    <div className={formStepperClasses}>
      <Stepper className={'form-stepper'} steps={steps} activeStep={currentStep} connectorStateColors={true} />
    </div>
  );
};

CustomFormStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
  className: PropTypes.string
};

export default CustomFormStepper;
