import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { ReactComponent as CardBlockIcon } from 'assets/card-block.svg';
import { ReactComponent as VirtualCardBusiness } from 'assets/cards/virtual-card-business.svg';
import { ReactComponent as VirtualCardIndividual } from 'assets/cards/virtual-card-invividual.svg';
import { CUSTOMER_TYPE, CARD_STATUS } from 'components/common/constants';
import { classNames } from 'uikit/utils';
import '../Cards.scss';

export const CardExample = ({ customerType, className, cardStatus, cardholderName }) => {
  const i18n = useContext(i18nContext);
  const isBlocked = cardStatus && cardStatus === (CARD_STATUS.SOFT_BLOCK || CARD_STATUS.HARD_BLOCK);
  const isClosed = cardStatus && cardStatus === CARD_STATUS.CLOSED;

  const inputClasses = classNames({
    'card-example-wrapper': true,
    'card-example-wrapper-disabled': isBlocked,
    [className]: true
  });

  return (
    <div className={inputClasses}>
      {customerType === CUSTOMER_TYPE.COMPANY ? (
        <VirtualCardBusiness className={isBlocked || isClosed ? 'card-example-icon-blocked' : ''} />
      ) : (
        <VirtualCardIndividual className={isBlocked || isClosed ? 'card-example-icon-blocked' : ''} />
      )}
      {isBlocked && (
        <div className='card-example-blocked'>
          <CardBlockIcon />
          <span>{i18n.getMessage('cards.details.virtual.blockedCardLabel')}</span>
        </div>
      )}
      {isClosed && (
        <div className='card-example-blocked'>
          <CardBlockIcon />
          <span>{i18n.getMessage('cards.details.virtual.closedCardLabel')}</span>
        </div>
      )}
      {cardholderName && <span className='cardholder-name'>{cardholderName}</span>}
    </div>
  );
};

CardExample.propTypes = {
  customerType: PropTypes.string,
  className: PropTypes.string,
  cardStatus: PropTypes.string,
  cardholderName: PropTypes.string
};
