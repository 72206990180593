import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import { PAGINATION_SIZES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { classNames } from 'uikit/utils';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './Pagination.scss';

const Pagination = ({
  isShowPaginationSize,
  paginationSize,
  handleChangePaginationSize,
  currentPage,
  numberOfPages,
  setCurrentPage,
  className,
  isLoading
}) => {
  const i18n = useContext(i18nContext);
  const paginationClasses = classNames({
    'pagination-wrapper': true,
    [className]: true
  });

  const paginationSizeOptions = PAGINATION_SIZES.map((size) => {
    return {
      key: size,
      value: size
    };
  });

  const handlePaginationSizeChange = (name, value) => {
    handleChangePaginationSize(value);
  };

  if (isLoading) {
    return (
      <div className={paginationClasses}>
        <div className='pagination-pages'>
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
        </div>
        <div className='pagination-counter'>
          <div className={'loader-placeholder'} />
        </div>
      </div>
    );
  }

  return (
    <div className={paginationClasses}>
      {isShowPaginationSize && (
        <InputDropDown
          isSpecificValue={true}
          className={'pagination-size-dropdown'}
          options={paginationSizeOptions}
          value={i18n.getMessage(`pagination.size`, { size: paginationSize })}
          onChange={handlePaginationSizeChange}
        />
      )}
      <div className='pagination-pages'>
        <button onClick={() => currentPage !== 0 && setCurrentPage(currentPage - 1)}>
          <ArrowLeft />
        </button>
        {numberOfPages !== 1 && (
          <button
            className={currentPage + 1 !== numberOfPages ? 'active' : ''}
            onClick={() => currentPage !== 0 && setCurrentPage(currentPage - 1)}
          >
            {currentPage + 1 === numberOfPages ? currentPage : currentPage + 1}
          </button>
        )}
        <button
          className={currentPage + 1 === numberOfPages ? 'active' : ''}
          onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1 === numberOfPages ? currentPage + 1 : currentPage + 2}
        </button>
        <button onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(currentPage + 1)}>
          <ArrowRight />
        </button>
      </div>
      <p className='pagination-counter'>
        <strong>{currentPage + 1}</strong> / {numberOfPages}
      </p>
    </div>
  );
};

Pagination.propTypes = {
  isShowPaginationSize: PropTypes.bool,
  paginationSize: PropTypes.number,
  handleChangePaginationSize: PropTypes.func,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Pagination;
