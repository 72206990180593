import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { ROUTE_PATHS } from 'routes/constants';
import TransactionsList from './TransactionsList';
import { EmptyTransactionsList } from './EmptyTransactionsList';
import i18nContext from 'components/i18n-context';
import { Container } from 'uikit/Container/Container';
import './LatestTransactions.scss';

const LatestTransactionsCompact = ({
  customerNumber,
  transactionsStore,
  transferSuccessful,
  isCardsTransactions = false,
  accountNumber
}) => {
  const i18n = useContext(i18nContext);
  useEffect(() => {
    if (customerNumber || transferSuccessful) {
      if (isCardsTransactions) {
        transactionsStore.getLatestTransactions(customerNumber, {
          wallets: accountNumber
        });
      } else {
        transactionsStore.getLatestTransactions(customerNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNumber, transferSuccessful, accountNumber]);

  const handleViewAllClick = () => {
    if (isCardsTransactions && accountNumber) {
      transactionsStore.setFilter('wallets', [accountNumber]);
      transactionsStore.setIsCardAccountTransactions(true);
    }
  };

  return (
    <section className={'right-section latest-transactions'}>
      <Container className={'transactions-container'} header={i18n.getMessage('container.latestTransactions')}>
        {transactionsStore.transactionList.length ? (
          <>
            <Link className={'link-normal transaction-link'} to={ROUTE_PATHS.TRANSACTIONS} onClick={handleViewAllClick}>
              {i18n.getMessage('latestTransactions.link.transactions')}
            </Link>
            <TransactionsList
              isLoading={transactionsStore.isLoading}
              compact
              transactions={transactionsStore?.transactionList}
            />
          </>
        ) : (
          <EmptyTransactionsList />
        )}
      </Container>
    </section>
  );
};

LatestTransactionsCompact.propTypes = {
  customerNumber: PropTypes.string,
  compact: PropTypes.bool,
  transferSuccessful: PropTypes.bool,
  isCardsTransactions: PropTypes.bool,
  accountNumber: PropTypes.string,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  transactionsStore: stores.transactionsStore,
  transferSuccessful: stores.paymentStore.isSuccess
}))(observer(LatestTransactionsCompact));
