import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import {
  CUSTOMER_PERMISSIONS,
  CONFIRMATION_HANDLE_TYPES,
  SMS_ERROR_CODE_DISABLE,
  BACKEND_MODULE_TYPES
} from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { isFullSecurityCode } from 'components/utils';
import { generateSecurityCode, resendSecurityCode } from 'services/requestAgent';
import { getErrorMessageForAlert } from 'services/utils';
import { getSecureUserPhone, updateConfirmationActionType } from 'services/authUtils';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';

export const PermissionScheme = ({
  closePopUp,
  email,
  error,
  clearError,
  submitPermissions,
  permissions,
  representativeForUpdate
}) => {
  const i18n = useContext(i18nContext);
  const [showSMS, setShowSms] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState(
    representativeForUpdate?.permissions || [CUSTOMER_PERMISSIONS.READ_ACCOUNT]
  );
  const [confirmationId, setConfirmationId] = useState(null);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setSecurityCode('');
    } else {
      setAlertState({ type: '', message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onCompleteSecurityCode = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleResendCode = async () => {
    clearError();
    await resendSecurityCode(BACKEND_MODULE_TYPES.API, confirmationId);
    isDisabled && setIsDisabled(false);
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onConfirmClick = async () => {
    if (showSMS) {
      await submitPermissions(currentPermissions, securityCode);
      return;
    }

    setShowSms(true);
    setShowCountDown(true);
    const { confirmation_id, confirmation_type } = await generateSecurityCode(BACKEND_MODULE_TYPES.API);
    updateConfirmationActionType(confirmation_type);
    setConfirmationId(confirmation_id);
  };

  const onCheckBoxChange = (key) => {
    if (key === CUSTOMER_PERMISSIONS.CREATE_MESSAGE) {
      // If 'View & Create messages' is selected, ensure 'View messages' is also selected
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_MESSAGES)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_MESSAGES);
        }
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.CREATE_MESSAGE)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.CREATE_MESSAGE);
        } else {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_MESSAGE), 1);
        }
        return updatedPermissions;
      });
    } else if (key === CUSTOMER_PERMISSIONS.READ_MESSAGES) {
      // If 'View messages' is deselected, also remove 'View & Create messages'
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_MESSAGES)) {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.READ_MESSAGES), 1);
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_MESSAGE), 1);
        } else {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_MESSAGES);
        }
        return updatedPermissions;
      });
    } else {
      // For other permissions, toggle the permission state
      if (!currentPermissions.includes(key)) {
        setCurrentPermissions((prev) => [...prev, key]);
      } else {
        setCurrentPermissions(currentPermissions.filter((perm) => perm !== key));
      }
    }
  };

  const isSelectAll = !permissions.filter((permission) => {
    return !currentPermissions.includes(permission);
  }).length;

  const renderButtonText = () => {
    if (showSMS) return i18n.getMessage('recovery.button.confirm');
    return i18n.getMessage(representativeForUpdate ? 'representatives.label.change' : 'representatives.label.add');
  };

  return (
    <div className={'scheme-wrapper permission'}>
      <div className={'representatives-add-header-wrapper'}>
        <h2 className={'representatives-popUp-header'}>{i18n.getMessage('representatives.header.permission')}</h2>
        <CloseIcon className={'representatives-icon'} onClick={closePopUp} />
      </div>
      <p className={`representatives-email ${showSMS ? 'disabled' : ''}`}>{email || 'Name@example.com'}</p>
      <CheckBox
        className={'representatives-checkbox full-checkbox'}
        isActive={isSelectAll}
        showCheckBox={true}
        disabled={showSMS}
        onChange={() => setCurrentPermissions(isSelectAll ? [CUSTOMER_PERMISSIONS.READ_ACCOUNT] : permissions)}
        value={i18n.getMessage('representatives.label.fullAccess')}
      />
      <div className={'permission-checkboxes-wrapper'}>
        {Object.keys(CUSTOMER_PERMISSIONS).map((permission) => (
          <CheckBox
            className={'representatives-checkbox'}
            key={`representatives-permission-${permission}-id`}
            isActive={currentPermissions?.includes(permission)}
            onChange={() => onCheckBoxChange(permission)}
            disabled={showSMS || permission === CUSTOMER_PERMISSIONS.READ_ACCOUNT}
            showCheckBox={true}
            value={i18n.getMessage(`representatives.permission.${permission}`)}
          />
        ))}
      </div>
      {showSMS && (
        <ConfirmCodeWrapper
          PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
          isDisabled={isDisabled}
          error={error}
          onComplete={onCompleteSecurityCode}
          showCountDown={showCountDown}
          handleResendCode={handleResendCode}
          onTimerEnd={() => setShowCountDown(false)}
          alertState={alertState}
          confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
        />
      )}
      <Button
        size={'large'}
        fullWidth={true}
        onClick={onConfirmClick}
        isDisabled={showSMS && !isFullSecurityCode(securityCode)}
      >
        {renderButtonText()}
      </Button>
    </div>
  );
};

PermissionScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  clearError: PropTypes.func,
  submitPermissions: PropTypes.func,
  error: PropTypes.any,
  representativeForUpdate: PropTypes.object,
  permissions: PropTypes.array
};
