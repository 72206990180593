import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { ReactComponent as PlusIcon } from 'assets/Subtract.svg';
import { USER_CUSTOMER_STATUSES } from 'components/common/constants';
import { getHeaderButtons } from 'components/common/MenuButtons';
import { PopUpLogOutConfirmationScheme } from 'components/common/PopUpScheme/PopUpLogOutConfirmationScheme';
import { ROUTE_PATHS } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';

const UserPanel = ({ onClick = () => {}, isMobile = false, userStore, messagesStore, registrationStore }) => {
  const navigate = useNavigate();
  const [showLogOutPopUp, setShowLogOutPopUp] = useState(false);
  const isHeaderButtonNames = useMediaQuery(MEDIA_QUERY.HEADER_BUTTON_NAMES);

  const onHeaderClick = (id, buttonClick) => {
    if (id === 4) {
      return setShowLogOutPopUp(true);
    }
    onClick ? onClick(buttonClick) : buttonClick();
  };

  const getAccountName = (account) => {
    if (!account) {
      return '';
    }
    return account.customer_account
      ? account.customer_account.company_name ||
          `${account.customer_account.first_name} ${account.customer_account.last_name}`
      : account.common_name;
  };

  const getAvailableAccountsOptions = () =>
    userStore?.userData?.availableAccounts.map((account) => ({
      key: account?.customer_account ? account?.customer_account?.account_number : account?.account_number,
      value: getAccountName(account)
    }));

  const showHeaderDropDown =
    !!userStore?.userData?.availableAccounts?.filter((acc) => acc.status === USER_CUSTOMER_STATUSES.VERIFIED) &&
    userStore.isRepresentativesEnabled();

  const handleChangeAccount = (name, value) =>
    value === userStore?.userData?.account?.account_number
      ? null
      : userStore.changeCurrentUserAccount(value) && onClick();

  const handleCreateAccount = () => {
    registrationStore.resetRegistrationStore();
    navigate(ROUTE_PATHS.CUSTOMER_REGISTRATION);
  };

  return (
    <>
      <div className='account-dropdown-container'>
        {showHeaderDropDown ? (
          <InputDropDown
            className='account-dropdown'
            placeholder={i18n.getMessage('multiAccountInputPlaceholder')}
            options={getAvailableAccountsOptions()}
            specificOptions={[
              {
                key: 'open-acc',
                content: (
                  <div className='open-acc'>
                    <PlusIcon /> <p>{i18n.getMessage('button.openAnAccount')}</p>
                  </div>
                )
              }
            ]}
            value={userStore?.userData?.account?.account_number}
            onChange={(name, key) => (key === 'open-acc' ? handleCreateAccount() : handleChangeAccount(name, key))}
          />
        ) : (
          <span className='current-account-name'>{getAccountName(userStore?.userData?.account)}</span>
        )}
      </div>
      <div className={`user-panel ${!isHeaderButtonNames && !isMobile ? 'collapsed' : ''}`}>
        {getHeaderButtons(!!userStore.userData.account).map((button) => (
          <IconButton
            key={button.id}
            text={isHeaderButtonNames || isMobile ? button.text : ''}
            onClick={() => onHeaderClick(button.id, button.click)}
            path={button.path}
            navigationType={button.id !== 4}
            Icon={button.icon}
            isDisabled={button.isDisabled}
            unreadTopics={button.path === ROUTE_PATHS.MESSAGES ? messagesStore.unreadTopics : null}
          />
        ))}
        <PopUp alignOnCenter show={showLogOutPopUp}>
          <PopUpLogOutConfirmationScheme onClose={() => setShowLogOutPopUp(false)} />
        </PopUp>
      </div>
    </>
  );
};

UserPanel.propTypes = {
  onClick: PropTypes.any,
  isMobile: PropTypes.bool,
  userStore: MobXPropTypes.observableObject,
  messagesStore: MobXPropTypes.observableObject,
  registrationStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  messagesStore: stores.messagesStore,
  registrationStore: stores.registrationStore
}))(observer(UserPanel));
