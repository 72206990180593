import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ReceiveIcon } from 'assets/receive.svg';
import { ReactComponent as CardCreatingIcon } from 'assets/card-creating-icon.svg';
import { ReactComponent as VirtualCardBusiness } from 'assets/cards/virtual-card-business.svg';
import { ReactComponent as VirtualCardIndividual } from 'assets/cards/virtual-card-invividual.svg';
import { cutCardNumber } from 'components/Cards/utils';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_TYPE, CARD_STATUS, CARD_TYPE } from 'components/common/constants';
import { classNames } from 'uikit/utils';
import '../../../Cards.scss';

export const CardDashboardView = ({ customerType, card, handleClick }) => {
  const i18n = useContext(i18nContext);
  const isCardCreating = card?.status === CARD_STATUS.CREATED;
  const isCardReplacing = card?.status === CARD_STATUS.REPLACE_REQUESTED;
  const cardWrapperClasses = classNames({
    'card-dashboard-view-wrapper': true,
    virtual: card?.type === CARD_TYPE.VIRTUAL,
    physical: card?.type === CARD_TYPE.PHYSICAL,
    'creating-card': isCardCreating || isCardReplacing
  });

  return (
    <button
      className={cardWrapperClasses}
      onClick={() => handleClick(card?.card_id)}
      disabled={isCardCreating || isCardReplacing}
    >
      <div className='card-image-block'>
        {customerType === CUSTOMER_TYPE.COMPANY ? <VirtualCardBusiness /> : <VirtualCardIndividual />}
      </div>
      {isCardCreating ? (
        <div className='creating-block'>
          <CardCreatingIcon />
          <span className='status-text'>{i18n.getMessage('card.status.creating')}</span>
        </div>
      ) : (
        <div className='card-data-block'>
          <div className='card-number-block'>
            <span className='card-number-text'>{i18n.getMessage(`cards.type.${card?.type}`)}</span>
            <span className='card-number-text'>{cutCardNumber(card?.card_number)}</span>
          </div>
          <div className='card-owner-block'>
            <span className='card-owner-text'>{card?.name}</span>
            <ReceiveIcon />
          </div>
        </div>
      )}
    </button>
  );
};

CardDashboardView.propTypes = {
  customerType: PropTypes.string,
  card: PropTypes.object,
  handleClick: PropTypes.func.isRequired
};
