/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import './PopUp.scss';
import { classNames } from '../utils';
import { useEffect } from 'react';

export const PopUp = ({ onClose = () => {}, show, children, className, alignOnCenter, isHighPriority }) => {
  const KEY_CODE = {
    escape: 27
  };

  useEffect(() => {
    const closePopup = (e) => e.keyCode === KEY_CODE.escape && onClose();
    window.addEventListener('keydown', closePopup);
    return () => window.removeEventListener('keydown', closePopup);
  }, []);

  if (!show) return null;

  const onBackgroundClick = (e) => {
    if (typeof e?.target?.className === 'string' && e?.target?.className?.includes('pop-up')) {
      onClose();
    }
  };

  const popUpCardClasses = classNames({
    'pop-up-card': true,
    [className]: true
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`pop-up ${isHighPriority ? 'high-priority-index' : ''} ${alignOnCenter ? 'align-on-center' : ''}`}
      onClick={(e) => onBackgroundClick(e)}
    >
      <div className={popUpCardClasses}>{children}</div>
    </div>
  );
};

PopUp.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.object,
  className: PropTypes.string,
  alignOnCenter: PropTypes.bool,
  isHighPriority: PropTypes.bool
};
