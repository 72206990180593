/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { filterWallesterAccounts } from './utils';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import { BackButton } from 'components/common/BackButton/BackButton';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import './Cards.scss';

const Cards = ({
  customerNumber,
  cardsPerAccountLimit,
  cardsPerCustomerLimit,
  cardsStore,
  messagesStore,
  userStore
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    return () => {
      cardsStore.resetCardsStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const wallesterAccounts = filterWallesterAccounts(userStore.userAccounts);

    cardsStore.initializeStoreState(customerNumber, wallesterAccounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.userAccounts]);

  useEffect(() => {
    if (
      cardsStore?.cards?.length > 0 &&
      cardsStore.selectedAccount?.wallet_number &&
      cardsPerAccountLimit &&
      cardsPerCustomerLimit
    ) {
      const isCardLimitPerAccountExceeded =
        cardsStore?.cards?.filter((card) => card?.wallet_number === cardsStore.selectedAccount?.wallet_number).length >=
        cardsPerAccountLimit;
      const isCardLimitPerCustomerExceeded = cardsStore?.cards?.length >= cardsPerCustomerLimit;

      if (
        (isCardLimitPerAccountExceeded || isCardLimitPerCustomerExceeded) &&
        (location.pathname !== ROUTE_PATHS.CARDS_CREATE || location.pathname !== ROUTE_PATHS.CARDS_CREATE_VIRTUAL)
      ) {
        navigate(ROUTE_PATHS.CARDS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardsStore?.cards?.length,
    cardsStore.selectedAccount?.wallet_number,
    cardsPerAccountLimit,
    cardsPerCustomerLimit
  ]);

  const setSupportMessageForCardAccountOpening = () => {
    messagesStore.setNewTopicData(
      i18n.getMessage('cards.noAccounts.openCardAccountRequest.subject'),
      i18n.getMessage('cards.noAccounts.openCardAccountRequest.message')
    );
  };

  return (
    <>
      {cardsStore.isInitialized && filterWallesterAccounts(userStore.userAccounts).length === 0 ? (
        <ContactUs
          icon={<SupportIcon />}
          header={i18n.getMessage('cards.noAccounts.title')}
          description={i18n.getMessage('cards.noAccounts.description')}
          button={i18n.getMessage('cards.noAccounts.button')}
          setSupportMessage={setSupportMessageForCardAccountOpening}
        />
      ) : (
        <section className={'right-section'}>
          {location.pathname !== ROUTE_PATHS.CARDS && <BackButton />}
          <Outlet />
        </section>
      )}
    </>
  );
};

Cards.propTypes = {
  customerNumber: PropTypes.string,
  cardsPerAccountLimit: PropTypes.number,
  cardsPerCustomerLimit: PropTypes.number,
  cardsStore: MobXPropTypes.observableObject,
  messagesStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  cardsPerAccountLimit: stores.userStore.userData.account?.cards_per_account_limit,
  cardsPerCustomerLimit: stores.userStore.userData.account?.cards_per_customer_limit,
  cardsStore: stores.cardsStore,
  messagesStore: stores.messagesStore,
  userStore: stores.userStore
}))(observer(Cards));
