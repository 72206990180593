import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { AuthorizationDateFilter } from './AuthorizationDateFilter';
import AuthorizationRangeFilter from './AuthorizationRangeFilter';
import { ReactComponent as FilterIcon } from 'assets/filter-mobile-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { filterWallesterAccounts } from 'components/Cards/utils';
import { CARD_AUTHORIZATIONS_STATUSES, CARD_AUTHORIZATIONS_STATUS } from 'components/common/constants';
import { getAccountTitle } from 'components/utils';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import 'react-datepicker/dist/react-datepicker.css';
import './AuthorizationsList.scss';

const AuthorizationsFilter = ({ cardsStore, userAccounts, isMobile }) => {
  const i18n = useContext(i18nContext);
  const [isOpen, setIsOpen] = useState(false);

  const accountsOptions = filterWallesterAccounts(userAccounts).map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account?.wallet_number,
      value: title,
      currency: account?.currency
    };
  });

  const statusOptions = CARD_AUTHORIZATIONS_STATUSES?.reduce((acc, status) => {
    if (status !== CARD_AUTHORIZATIONS_STATUS.CLEARED) {
      acc.push({
        key: status,
        value: i18n.getMessage('cards.authorizations.status.' + status)
      });
    }
    return acc;
  }, []);

  const handleSetFilter = (name, value) => cardsStore.setFilter(name, value);

  const handleSetDateFilter = (name, value) => {
    cardsStore.clearPredefinedFilter();
    cardsStore.setFilter(name, value);
  };

  const handleSetRangeFilter = (filterType, dateRang) => cardsStore.setRangeFilter(filterType, dateRang);

  if (isMobile && !isOpen) {
    return (
      <div className='authorization-filter-compact'>
        <AuthorizationRangeFilter name='tabs' value={cardsStore.rangeFilter} onChange={handleSetRangeFilter} />
        <div className='filter-compact-wrapper'>
          <Button className='filter-button' type={'primary'} size={'large'} onClick={() => setIsOpen(true)}>
            <FilterIcon />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form action='#' id='send' className='authorization-filter-wrapper'>
      {isMobile && (
        <div className='filter-mobile-header'>
          <h1>{i18n.getMessage('menu.filters.text')}</h1>
          <CloseIcon onClick={() => setIsOpen(false)} className='filters-close-icon' />
        </div>
      )}
      <AuthorizationRangeFilter name='tabs' value={cardsStore.rangeFilter} onChange={handleSetRangeFilter} />
      <div className='filter-wrapper'>
        <div className='filter-wrapper-line'>
          <AuthorizationDateFilter
            name='from_date'
            value={cardsStore.filters.from_date}
            onChange={handleSetDateFilter}
            label={i18n.getMessage('cards.authorizations.text.from')}
          />
          <AuthorizationDateFilter
            name='to_date'
            value={cardsStore.filters.to_date}
            onChange={handleSetDateFilter}
            label={i18n.getMessage('cards.authorizations.text.to')}
          />
          <InputDropDown
            className='status'
            placeholder={i18n.getMessage('cards.authorizations.text.status')}
            name='statuses'
            value={cardsStore.filters.statuses}
            options={statusOptions}
            onChange={handleSetFilter}
            isMulti
          />
          <InputDropDown
            className='wallet'
            name='wallets'
            options={accountsOptions}
            value={cardsStore.filters.wallets}
            onChange={handleSetFilter}
            isMulti
          />
          {isMobile && (
            <div className='filter-buttons-wrapper'>
              <Button className='filter-button' size='large' type='outline' onClick={() => cardsStore.clearFilters()}>
                {i18n.getMessage('cards.authorizations.button.clear')}
              </Button>
              <Button className='filter-button' size='large' onClick={() => setIsOpen(false)}>
                {i18n.getMessage('cards.authorizations.button.apply')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

AuthorizationsFilter.propTypes = {
  cardsStore: MobXPropTypes.observableObject,
  userAccounts: PropTypes.array,
  isMobile: PropTypes.bool
};

export default inject((stores) => ({
  userAccounts: stores.userStore.userAccounts,
  cardsStore: stores.cardsStore
}))(observer(AuthorizationsFilter));
