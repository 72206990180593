import { Container } from '../../uikit/Container/Container';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useContext } from 'react';
import i18nContext from '../i18n-context';
import { ContactUs } from '../common/ContactUs/ContactUs';
import { ReactComponent as SupportIcon } from '../../assets/support.svg';
import Button from '../../uikit/Button/Button';
import { ROUTE_PATHS } from '../../routes/constants';
import { useNavigate } from 'react-router-dom';

const Manual = ({ userStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC);
  };

  return (
    <Container className='manual-verification-container' header={i18n.getMessage('verification.manual.header')}>
      <div className='manual-verification-wrapper'>
        <div className='manual-verification-text'>{i18n.getMessage('verification.manual.description')}</div>
        <div className='manual-verification-button-container'>
          <Button type={'primary'} roleType={'submit'} className={'btn-size'} size={'large'} onClick={handleClick}>
            {i18n.getMessage('dashboard.noAccounts.button')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

Manual.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Manual));
