import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as NotFoundIcon } from 'assets/notFound.svg';
import { ReactComponent as DeclinedIcon } from 'assets/Declined.svg';
import { ReactComponent as ClosedAccountIcon } from 'assets/closed-account.svg';
import { ReactComponent as PlusIcon } from 'assets/Plus.svg';
import i18nContext from 'components/i18n-context';
import { USER_CUSTOMER_STATUSES } from 'components/common/constants';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import './NoOutlet.scss';

const NOT_FOUND = 'ACCOUNT_NOT_FOUND';
const NO_ACCOUNT = 'ACCOUNT_NOT_REGISTERED';

const NoOutlet = ({ error }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const outletState = {
    icon: <DeclinedIcon />,
    label: i18n.getMessage('noOutlet.declined.label'),
    description: i18n.getMessage('noOutlet.declined.description'),
    buttonLabel: i18n.getMessage('noOutlet.declined.button')
  };

  if (!error) {
    return null;
  }

  if (error?.code === NOT_FOUND) {
    outletState.icon = <NotFoundIcon />;
    outletState.label = i18n.getMessage('noOutlet.notFound.label');
    outletState.description = i18n.getMessage('noOutlet.notFound.description', {
      appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION
    });
    outletState.buttonLabel = i18n.getMessage('noOutlet.button.text');
  }

  if (error?.code === USER_CUSTOMER_STATUSES.DECLINED) {
    outletState.icon = <DeclinedIcon />;
    outletState.label = i18n.getMessage('noOutlet.declined.label');
    outletState.description = i18n.getMessage('noOutlet.declined.description');
    outletState.buttonLabel = i18n.getMessage('noOutlet.declined.button');
  }

  if (error?.code === USER_CUSTOMER_STATUSES.CLOSED) {
    outletState.icon = <ClosedAccountIcon />;
    outletState.label = i18n.getMessage('noOutlet.closed.label');
    outletState.description = i18n.getMessage('noOutlet.closed.description');
    outletState.buttonLabel = i18n.getMessage('noOutlet.closed.button');
  }

  if (error?.code === NO_ACCOUNT) {
    return (
      <div className='verification'>
        <PlusIcon className='svg-plus corporate-icon' />
        <div className='bank-wrapper'>
          <h1 className='bank-header account'>{i18n.getMessage('noOutlet.accountNotRegistered.label')}</h1>
          <Button
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={() => navigate(ROUTE_PATHS.CUSTOMER_REGISTRATION)}
          >
            {i18n.getMessage('button.openAnAccount')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <section className='right-section no-outlet'>
      <div className='wrapper'>
        <div className='no-outlet__left-side'>{outletState.icon}</div>
        <div className='no-outlet__right-side'>
          <h2 className='no-outlet__label'>{outletState.label}</h2>
          <p className='no-outlet__description'>{outletState.description}</p>
          {error?.code === USER_CUSTOMER_STATUSES.DECLINED || error?.code === USER_CUSTOMER_STATUSES.CLOSED ? (
            <Button
              type={'primary'}
              roleType={'submit'}
              className={'btn-size'}
              size={'large'}
              onClick={() => navigate(ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC)}
            >
              {outletState.buttonLabel}
            </Button>
          ) : (
            <Button
              size='large'
              onClick={() => (location.href = 'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL)}
              className='no-outlet__button'
            >
              {outletState.buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

NoOutlet.propTypes = {
  error: PropTypes.object
};

export default NoOutlet;
