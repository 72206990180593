import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes/constants';
import AuthorizationsTable from './AuthorizationsTable';
import { EmptyAuthorizationsList } from './EmptyAuthorizationsList';
import i18nContext from 'components/i18n-context';
import { Container } from 'uikit/Container/Container';
import './AuthorizationsList.scss';

const AuthorizationsListCompact = ({ customerNumber, cardsStore }) => {
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (customerNumber && cardsStore.selectedAccount) {
      cardsStore.getLatestAuthorizationsList(customerNumber, {
        wallets: cardsStore.selectedAccount?.wallet_number
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNumber, cardsStore.selectedAccount?.wallet_number]);

  return (
    <section className={'right-section latest-authorizations'}>
      <Container className={'authorizations-container'} header={i18n.getMessage('container.latestAuthorizations')}>
        {cardsStore.authorizationsList.length ? (
          <>
            <Link className={'link-normal authorization-link'} to={ROUTE_PATHS.CARDS_AUTHORIZATIONS}>
              {i18n.getMessage('cards.authorizations.link.viewAll')}
            </Link>
            <AuthorizationsTable
              isLoading={cardsStore.isAuthorizationsLoading}
              compact
              authorizations={cardsStore?.authorizationsList}
            />
          </>
        ) : (
          <EmptyAuthorizationsList />
        )}
      </Container>
    </section>
  );
};

AuthorizationsListCompact.propTypes = {
  customerNumber: PropTypes.string,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  cardsStore: stores.cardsStore
}))(observer(AuthorizationsListCompact));
