import { makeAutoObservable, runInAction } from 'mobx';
import {
  CUSTOMER_TYPE,
  REGISTRATION_CUSTOMER_TYPE,
  REGISTRATION_STATUSES,
  REGISTRATION_STEPS
} from 'components/common/constants';
import { getAllParamsFromLocalStorage } from 'components/utils';
import { removeConfirmationActionType } from 'services/authUtils';
import {
  confirmSmsCode,
  createAccount,
  confirmAccountEmail,
  createUser,
  resendCodeAccountEmail
} from 'services/requestAgent';

const EMAIL_CONFIRMATION_ERROR = 'WRONG_EMAIL_CONFIRMATION';
const EMAIL_CODE_EXCEEDED_ERROR = 'EMAIL_CONFIRMATION_EXCEEDED_ATTEMPTS';
const MAX_ATTEMPTS_EMAIL_CODE = 5;

class RegistrationStore {
  isLoading = false;
  isAcceptTerms = false;
  isAfterUserRegistration = false;
  error = null;
  registrationType = REGISTRATION_CUSTOMER_TYPE.BUSINESS;
  currentRegistrationStep = REGISTRATION_STEPS.USER_REGISTRATION;
  verificationCode = '';
  verificationCodeAttempts = 0;
  registrationStatus = null;
  responseId = null;
  userId = null;
  confirmationId = null;

  generalFields = {
    email: '',
    password: '',
    passwordRepeat: '',
    code: '',
    codeRepeat: '',
    phoneNumber: process.env.REACT_APP_DEFAULT_DIAL_CODE,
    captcha: ''
  };

  personalFields = {
    firstName: '',
    surname: ''
  };

  businessFields = {
    companyName: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setEmail(email) {
    this.generalFields.email = email;
  }

  setPassword(password) {
    this.generalFields.password = password;
  }

  setPasswordRepeat(passwordRepeat) {
    this.generalFields.passwordRepeat = passwordRepeat;
  }

  setCaptcha(captcha) {
    this.generalFields.captcha = captcha;
  }

  setCode(code) {
    this.generalFields.code = code;
  }

  setCodeRepeat(codeRepeat) {
    this.generalFields.codeRepeat = codeRepeat;
  }

  setFirstName(firstName) {
    this.personalFields.firstName = firstName;
  }

  setSurname(surname) {
    this.personalFields.surname = surname;
  }

  setCompanyName(companyName) {
    this.businessFields.companyName = companyName;
  }

  setVerificationCode(verificationCode) {
    this.verificationCode = verificationCode;
  }

  setRegistrationStatus(registrationStatus) {
    this.registrationStatus = registrationStatus;
  }

  setPhoneNumber(phoneNumber) {
    this.generalFields.phoneNumber = phoneNumber;
  }

  setRegistrationType(type) {
    this.registrationType = type;
  }

  setCurrentRegistrationStep(currentStep) {
    this.currentRegistrationStep = currentStep;
  }

  setResponseId(id) {
    this.responseId = id;
  }

  setIsAfterRegistrationUser(status) {
    this.isAfterUserRegistration = status;
  }

  setConfirmationId(id) {
    this.confirmationId = id;
  }

  clearError() {
    this.error = null;
  }

  resetRegistrationStore() {
    this.isLoading = false;
    this.isAcceptTerms = false;
    this.isAfterUserRegistration = false;
    this.error = null;
    this.registrationStatus = null;
    this.verificationCode = '';
    this.generalFields.email = '';
    this.generalFields.password = '';
    this.generalFields.passwordRepeat = '';
    this.personalFields.firstName = '';
    this.personalFields.surname = '';
    this.businessFields.companyName = '';
    this.generalFields.code = '';
    this.generalFields.codeRepeat = '';
    this.registrationType = REGISTRATION_CUSTOMER_TYPE.BUSINESS;
    this.currentRegistrationStep = REGISTRATION_STEPS.USER_REGISTRATION;
    this.responseId = null;
    this.confirmationId = null;
  }

  async registerUser() {
    try {
      this.setIsLoading(true);
      const { confirmationId } = await createUser(
        {
          phone: this.generalFields.phoneNumber,
          password: this.generalFields.password,
          user_id: this.userId ? this.userId : ''
        },
        this.generalFields.captcha
      );

      if (confirmationId) {
        this.setConfirmationId(confirmationId);
      } else {
        runInAction(() => {
          removeConfirmationActionType();
          this.verificationCodeAttempts = 0;
        });
        this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_PHONE_CONFIRMATION);
        this.setCurrentRegistrationStep(REGISTRATION_STEPS.USER_REGISTRATION_CONFIRMATION);
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async registerPersonalAccount() {
    try {
      this.setIsLoading(true);
      const data = {
        account_type: CUSTOMER_TYPE.INDIVIDUAL.toUpperCase(),
        email: this.generalFields.email,
        first_name: this.personalFields.firstName,
        last_name: this.personalFields.surname,
        user_id: this.userId ? this.userId : ''
      };
      const { response_id: responseId, user_id: userId } = await createAccount(data);
      this.setResponseId(responseId);
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION);
      this.setCurrentRegistrationStep(REGISTRATION_STEPS.CUSTOMER_CREATION_CONFIRMATION);
      this.setIsLoading(false);
      this.verificationCodeAttempts = 0;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async registerBusinessAccount() {
    try {
      this.setIsLoading(true);
      const data = {
        account_type: CUSTOMER_TYPE.COMPANY.toUpperCase(),
        email: this.generalFields.email,
        company_name: this.businessFields.companyName,
        user_id: this.userId ? this.userId : ''
      };
      const { response_id: responseId } = await createAccount(data);
      this.setResponseId(responseId);
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_EMAIL_CONFIRMATION);
      this.setCurrentRegistrationStep(REGISTRATION_STEPS.CUSTOMER_CREATION_CONFIRMATION);
      this.setIsLoading(false);
      this.verificationCodeAttempts = 0;
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendVerificationCode() {
    try {
      this.setIsLoading(true);
      this.verificationCodeAttempts++;
      const { responseId } = await confirmAccountEmail(this.verificationCode, this.responseId);
      this.setResponseId(responseId);
      // if code is valid response id will be null
      if (responseId) {
        throw {
          code:
            this.verificationCodeAttempts < MAX_ATTEMPTS_EMAIL_CODE
              ? EMAIL_CONFIRMATION_ERROR
              : EMAIL_CODE_EXCEEDED_ERROR
        };
      }
      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED);
      this.setCurrentRegistrationStep(REGISTRATION_STEPS.REGISTRATION_COMPLETED);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async resendVerificationCode() {
    try {
      this.setIsLoading(true);
      await resendCodeAccountEmail(this.responseId);
      this.verificationCodeAttempts = 0;
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async sendCode() {
    try {
      this.setIsLoading(true);
      const allParams = getAllParamsFromLocalStorage();
      await confirmSmsCode(this.generalFields.code, allParams);

      this.setRegistrationStatus(REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED);
      this.setCurrentRegistrationStep(REGISTRATION_STEPS.CUSTOMER_CREATION);
      this.setIsLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  changeConfirmationEmail() {
    runInAction(() => {
      this.registrationStatus = null;
      this.currentRegistrationStep = REGISTRATION_STEPS.CUSTOMER_CREATION;
    });
  }

  changePhoneNumber() {
    runInAction(() => {
      this.registrationStatus = REGISTRATION_STATUSES.REGISTRATION_STATUS_SECOND_FACTOR_CONFIRMATION_PHONE;
    });
  }
}

export default new RegistrationStore();
