import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as StatusIcon } from 'assets/mobile-transaciton-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/iconButtons/profile.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';

import './LatestPaymentObligations.scss';

export const LatestPaymentObligationItemLoader = ({ isMobile }) => {
  if (isMobile) {
    return (
      <div className='obligation-item-mobile'>
        <div className='item-mobile-top'>
          <div className='item-mobile-date'>
            <CalendarIcon className='icon' />
            <div className={'loader-placeholder'} />
          </div>
        </div>
        <div className='item-mobile-amount'>
          <WalletIcon className='icon' />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
        </div>
      </div>
    );
  }

  return (
    <tr className='obligation-item'>
      <td className='obligation-date'>
        <div className={'loader-placeholder'} />
        <div className={'loader-placeholder'} />
      </td>
      <td className='obligation-purpose'>
        <div className={'loader-placeholder'} />
      </td>
      <td className={'obligation-amount'}>
        <div className={'loader-placeholder'} />
      </td>
      <td className='obligation-exclamation'>
        <div className={'loader-placeholder'} />
      </td>
    </tr>
  );
};

LatestPaymentObligationItemLoader.propTypes = {
  isMobile: PropTypes.bool
};
