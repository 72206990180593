import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/Message_open_error.svg';
import ComplexTranslate from 'components/ComplexTranslate';
import i18nContext from 'components/i18n-context';
import { BACKEND_MODULE_TYPES, CONFIRMATION_HANDLE_TYPES, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { isFullSecurityCode } from 'components/utils';
import {
  getConfirmationActionType,
  getSecureUserPhone,
  setConfirmationActionType,
  updateConfirmationActionType
} from 'services/authUtils';
import { generateSecurityCode, resendSecurityCode } from 'services/requestAgent';
import { getErrorMessageForAlert } from 'services/utils';
import Button from 'uikit/Button/Button';

export const DeleteUserScheme = ({ representativeMail, handleRemoveClick, closePopUp, error, clearError }) => {
  const i18n = useContext(i18nContext);
  const [isDeleteClick, setIsDeleteClick] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setSecurityCode('');
    } else {
      setAlertState({ type: '', message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleDeleteClick = async () => {
    if (isDeleteClick) return handleRemoveClick(securityCode);

    setIsDeleteClick(true);
    setShowCountDown(true);
    const { confirmation_id, confirmation_type } = await generateSecurityCode(BACKEND_MODULE_TYPES.API);
    updateConfirmationActionType(confirmation_type);
    setConfirmationId(confirmation_id);
  };

  const onCompleteSecurityCode = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleResendCode = async () => {
    clearError();
    await resendSecurityCode(BACKEND_MODULE_TYPES.API, confirmationId);
    isDisabled && setIsDisabled(false);
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  return (
    <div className={'scheme-wrapper pop-up-success delete'}>
      <SuccessIcon />
      <h2 className={'scheme-success-header'}>{i18n.getMessage('representatives.header.removeRepresentative')}</h2>
      <p className={'scheme-success-message'}>
        <ComplexTranslate
          text={'representatives.removeRepresentative'}
          interpolation={{
            representative: <strong>{representativeMail}</strong>
          }}
        />
      </p>
      {isDeleteClick && (
        <ConfirmCodeWrapper
          PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
          isDisabled={isDisabled}
          error={error?.code}
          onComplete={onCompleteSecurityCode}
          showCountDown={showCountDown}
          handleResendCode={handleResendCode}
          onTimerEnd={() => setShowCountDown(false)}
          alertState={alertState}
          confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
        />
      )}
      <div className={'scheme-wrapper-buttons'}>
        <Button
          className={'scheme-button'}
          size={'large'}
          type={'outline'}
          onClick={handleDeleteClick}
          isDisabled={isDeleteClick && !isFullSecurityCode(securityCode)}
        >
          {i18n.getMessage(isDeleteClick ? 'representatives.text.confirm' : 'representatives.text.yes')}
        </Button>
        <Button className={'scheme-error-button scheme-button'} size={'large'} onClick={closePopUp}>
          {i18n.getMessage('representatives.text.no')}
        </Button>
      </div>
    </div>
  );
};

DeleteUserScheme.propTypes = {
  representativeMail: PropTypes.string,
  handleRemoveClick: PropTypes.func,
  closePopUp: PropTypes.func.isRequired,
  error: PropTypes.object,
  clearError: PropTypes.func
};
