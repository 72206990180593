import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import './ContactUs.scss';

export const ContactUs = ({ icon, header, description, support, link, button, setSupportMessage }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (setSupportMessage) setSupportMessage();
    navigate(ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC);
  };

  return (
    <div className='contact-us-container'>
      {icon}
      <div className='contact-us-wrapper'>
        <h1 className='contact-us-header'>{header}</h1>
        {description && (
          <p className='contact-us-description'>
            {description}
            {link && (
              <a className='contact-us-link' href={`mailto:${link}`}>
                {link}
              </a>
            )}
          </p>
        )}
        {support && <p className='contact-us-description'>{support}</p>}

        <Button type={'primary'} roleType={'submit'} className={'btn-size'} size={'large'} onClick={handleClick}>
          {button}
        </Button>
      </div>
    </div>
  );
};

ContactUs.propTypes = {
  icon: PropTypes.object,
  header: PropTypes.string,
  description: PropTypes.string,
  support: PropTypes.string,
  link: PropTypes.string,
  button: PropTypes.string,
  setSupportMessage: PropTypes.func
};
