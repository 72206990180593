import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { TemplatesTableRow } from './components/TemplatesTableRow/TemplatesTableRow';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { TEMPLATES_TOOLTIP_TEXT } from 'components/common/constants';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TemplatesTable.scss';

const templatesArray = [
  {
    templateName: 'Template 1',
    payeeRequisites: 'GB12SEOU19870010124736',
    payeeName: 'John Doe'
  },
  {
    templateName: 'John Doe',
    payeeRequisites: 'GB12SEOU19870010124736',
    payeeName: 'John Doe'
  },
  {
    templateName: 'Jenny D',
    payeeRequisites: 'GB26 FINM 0000 0029 5537 6500 2326 5454',
    payeeName: 'Jenny Doe'
  },
  {
    templateName: 'partner',
    payeeRequisites: 'GB26 FINM 0000 0029 5537 65',
    payeeName: 'John Doe 2'
  },
  {
    templateName: 'partner companies',
    payeeRequisites: 'GB19TANF00988285042319',
    payeeName: 'Jack Doe'
  },
  {
    templateName: 'Samanta Doe request',
    payeeRequisites: 'GB77 FINM 0000 0032 7633 53',
    payeeName: 'Samanta Doe'
  }
];

const TemplatesTable = ({ userWallets, transactionsStore, cardsStore, paymentStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToPayment = (template) => {
    //TODO need to add template to paymentStore logic
    navigate(ROUTE_PATHS.NEW_PAYMENT);
  };

  const addNewTemplate = () => {
    navigate(ROUTE_PATHS.TEMPLATES_CREATE);
  };

  const editTemplate = (index) => {
    navigate(ROUTE_PATHS.TEMPLATES_EDIT);
  };

  const removeTemplate = (index) => {
    // TODO: need to add remove template logic
  };

  return (
    <Container className='templates-container' header={i18n.getMessage('container.templates')}>
      <div className='actions-wrapper'>
        <Button className='add-new-template-button' size='large' type='outline' onClick={addNewTemplate}>
          {i18n.getMessage('templates.button.addNewTemplate')}
        </Button>
        <Input
          className='search-input'
          name='search_text'
          value={transactionsStore.filters.search_text}
          // onChange={handleSearchTest}
          Icon={SearchIcon}
        />
      </div>

      <div className='templates-list'>
        {!isMobile && (
          <div className='templates-head'>
            <p className='head-template-name'>{i18n.getMessage('templates.tableHeader.templateName')}</p>
            <p className='head-payee-requisites'>{i18n.getMessage('templates.tableHeader.payeeRequisites')}</p>
            <p className='head-payee-name'>{i18n.getMessage('templates.tableHeader.payeeName')}</p>
            <p className='head-actions'>{i18n.getMessage('templates.tableHeader.actions')}</p>
          </div>
        )}

        {templatesArray?.map((template, index) => {
          return (
            <TemplatesTableRow
              key={index}
              type='list'
              templateName={template.templateName}
              payeeRequisites={template.payeeRequisites}
              payeeName={template.payeeName}
              onPaymentClick={() => goToPayment(template)}
              onEditTemplateClick={() => editTemplate(index)}
              onRemoveTemplateClick={() => removeTemplate(index)}
              tooltipText={TEMPLATES_TOOLTIP_TEXT}
            />
          );
        })}
      </div>
      <PopUp className='account-info' show={popUpState.open} onClose={closePopUp}>
        <PopUpScheme activeWallet={popUpState.account} onClose={closePopUp} />
      </PopUp>
    </Container>
  );
};

TemplatesTable.propTypes = {
  transactionsStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  cardsStore: MobXPropTypes.observableObject,
  userWallets: PropTypes.array
};

export default inject((stores) => ({
  userWallets: stores.userStore?.userWallets,
  transactionsStore: stores.transactionsStore,
  paymentStore: stores.paymentStore,
  cardsStore: stores.cardsStore
}))(observer(TemplatesTable));
