import enGeneral from './en.json';
import enCountries from './countries/countries_en.json';
import enShuftiJurisdictionCountries from './countries/shufti_jurisdiction_countries_en.json';

import ru from './ru.json';

export default {
  en: {
    ...enGeneral,
    ...enCountries,
    ...enShuftiJurisdictionCountries
  },
  ru
};
