import { createRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import i18nContext from 'components/i18n-context';
import { EXCLUDED_COUNTRIES } from 'components/common/constants';
import ComplexTranslate from 'components/ComplexTranslate';
import { getRandomHash } from 'components/Registration/utils';
import { getRegistrationPasswordValidation } from 'components/utils';
import { prepareFieldErrors, replaceSpaces } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import CustomCaptcha from 'uikit/CustomCaptcha';
import Input from 'uikit/Input/Input';
import PasswordInput from 'uikit/PasswordInput';

const UserRegistration = ({ registrationStore }) => {
  const i18n = useContext(i18nContext);
  const captchaProvider = process.env.REACT_APP_CAPTCHA_PROVIDER;
  const isHoneypotFields = process.env.REACT_APP_HONEYPOT_FIELDS_STATUS === 'ON';
  const captchaSiteKey =
    process.env.REACT_APP_CAPTCHA_PROVIDER === 'GOOGLE'
      ? process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY?.trim()
      : process.env.REACT_APP_CAPTCHA_PROVIDER === 'TURNSTILE'
        ? process.env.REACT_APP_TURNSTILE_CAPTCHA_SITE_KEY?.trim()
        : null;
  const recaptchaRef = createRef();
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [fields, setFields] = useState(
    isHoneypotFields
      ? { phone: '', password: '', honeypotPhone: '', honeypotPassword: '', captcha: '' }
      : { phone: 'phone', password: 'password', captcha: 'captcha' }
  );

  const handleResetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  /* Fields name initialization */
  useEffect(() => {
    if (isHoneypotFields) {
      setFields({
        phone: 'phone' + getRandomHash(),
        password: 'password' + getRandomHash(),
        honeypotPhone: 'phone',
        honeypotPassword: 'password',
        captcha: 'captcha'
      });
    }
  }, [isHoneypotFields]);

  const form = useFormik({
    initialValues: isHoneypotFields
      ? {
          [fields.phone]: registrationStore.generalFields.phoneNumber,
          [fields.password]: registrationStore.generalFields.password,
          [fields.honeypotPhone]: '',
          [fields.honeypotPassword]: '',
          [fields.captcha]: registrationStore.generalFields.captcha
        }
      : {
          [fields.phone]: registrationStore.generalFields.phoneNumber,
          [fields.password]: registrationStore.generalFields.password,
          [fields.captcha]: registrationStore.generalFields.captcha
        },
    validateOnChange: false,
    validationSchema: Yup.object(
      isHoneypotFields
        ? {
            [fields.phone]: Yup.string()
              .nullable(true)
              .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
              .required(i18n.getMessage('register.error.emptyPhone')),
            [fields.password]: getRegistrationPasswordValidation(),
            [fields.honeypotPhone]: Yup.string(),
            [fields.honeypotPassword]: Yup.string(),
            [fields.captcha]: captchaSiteKey
              ? Yup.string().required(i18n.getMessage('register.error.emptyCaptcha'))
              : Yup.string().nullable(true).notRequired()
          }
        : {
            [fields.phone]: Yup.string()
              .nullable(true)
              .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
              .required(i18n.getMessage('register.error.emptyPhone')),
            [fields.password]: getRegistrationPasswordValidation(),
            [fields.captcha]: captchaSiteKey
              ? Yup.string().required(i18n.getMessage('register.error.emptyCaptcha'))
              : Yup.string().nullable(true).notRequired()
          }
    ),
    onSubmit: (values) => {
      // eslint-disable-next-line max-len
      if (
        isPasswordVerified &&
        (!isHoneypotFields || (isHoneypotFields && !values[fields.honeypotPhone] && !values[fields.honeypotPassword]))
      ) {
        registrationStore.setPhoneNumber(values[fields.phone]);
        registrationStore.setPassword(replaceSpaces(values[fields.password]));
        registrationStore.setCaptcha(values[fields.captcha]);
        registrationStore.registerUser();
      }
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, submitCount, setFieldValue } = form;

  useEffect(() => {
    if (registrationStore.error || errors?.[fields.phone] || errors?.[fields.password]) {
      handleResetCaptcha();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationStore.error, errors?.[fields.phone], errors?.[fields.password]]);

  return (
    <>
      <form action={'#'} id={'send'} onSubmit={handleSubmit} className={'form'} autoComplete={'off'}>
        {/* Real fields --> */}
        <Input
          type={'phone'}
          label={i18n.getMessage('register.label.phone')}
          name={fields.phone}
          value={values[fields.phone]}
          setValue={setFieldValue}
          onChange={handleChange}
          onBlur={() => validateField(fields.phone)}
          setCountryCode={setCountryCode}
          initialStatus={submitCount}
          excludeCountries={EXCLUDED_COUNTRIES}
          isRequired={true}
          error={
            // eslint-disable-next-line max-len
            errors?.[fields.phone] ||
            (registrationStore.error && prepareFieldErrors(i18n, registrationStore?.error, undefined, true)?.phone)
          }
        />
        <PasswordInput
          name={fields.password}
          value={values[fields.password] || ''}
          onChange={handleChange}
          onBlur={() => validateField('password')}
          setIsPasswordVerified={setIsPasswordVerified}
          initialStatus={submitCount}
          label={i18n.getMessage('register.label.password')}
          placeholder={i18n.getMessage('register.password.placeholder')}
          error={
            errors?.[fields.password] ||
            (registrationStore.error && prepareFieldErrors(i18n, registrationStore?.error, undefined, true)?.password)
          }
        />
        {/* H o n e y p o t fields --> */}
        {isHoneypotFields && (
          <>
            <Input
              className={'d-none'}
              type={'phone'}
              label={i18n.getMessage('register.label.phone')}
              name={fields.honeypotPhone}
              value={values[fields.honeypotPhone]}
              setValue={setFieldValue}
              onChange={handleChange}
              setCountryCode={setCountryCode}
            />
            <Input
              className={'d-none'}
              type={'password'}
              label={i18n.getMessage('register.label.password')}
              placeholder={i18n.getMessage('register.password.placeholder')}
              name={fields.honeypotPassword}
              value={replaceSpaces(values[fields.honeypotPassword])}
              onChange={handleChange}
            />
          </>
        )}
        {/* H o n e y p o t fields end --> */}
        {captchaProvider && captchaSiteKey && (
          <CustomCaptcha
            captchaProvider={captchaProvider}
            siteKey={captchaSiteKey}
            error={errors?.captcha}
            onChange={(token) => setFieldValue(fields.captcha, token)}
            recaptchaRef={recaptchaRef}
          />
        )}
        <Button
          className={'user'}
          type={'primary'}
          roleType={'submit'}
          size={'large'}
          fullWidth={true}
          onClick={() => {}}
        >
          {i18n.getMessage('register.button.next')}
        </Button>
      </form>
      <p className={'register-account-text'}>
        {i18n.getMessage('register.text.haveAccount')}
        <a href={ROUTE_PATHS.LOGIN} className={'link-normal mx-1'}>
          {i18n.getMessage('login.returnToLogIn')}
        </a>
      </p>
      <div className={'register-createAccount-wrapper'}>
        <ComplexTranslate
          text={'register.text.creatingAccount'}
          interpolation={{
            user: (
              <a
                href={process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS}
                target={'_blank'}
                className={'link-normal'}
                rel={'noreferrer'}
              >
                {i18n.getMessage('register.text.userAgreement')}
              </a>
            ),
            privacy: (
              <a
                href={process.env.REACT_APP_LINK_PRIVACY_POLICY}
                target={'_blank'}
                className={'link-normal'}
                rel={'noreferrer'}
              >
                {i18n.getMessage('register.text.userPrivacy')}
              </a>
            )
          }}
        />
      </div>
    </>
  );
};

UserRegistration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  error: PropTypes.any
};

export default inject('registrationStore')(observer(UserRegistration));
