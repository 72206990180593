import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Loader from 'components/common/Loader';
import i18nContext from 'components/i18n-context';
import styles from 'uikit/stylesheets/variables.scss';

const CountdownTimer = ({ duration, quoteId, onComplete }) => {
  const i18n = useContext(i18nContext);

  const [remainingTime, setRemainingTime] = useState(duration);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (quoteId) {
      setRemainingTime(duration);
      setKey((prevState) => prevState + 1);
    } else {
      setRemainingTime(0);
    }
  }, [quoteId]);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className='exchange-rate-timer-content'>
          <Loader className='exchange-rate-timer-loader' isText={false} />
        </div>
      );
    }

    return (
      <div className='exchange-rate-timer-content'>
        <div className='exchange-rate-timer-remaining-time'>{remainingTime}</div>
      </div>
    );
  };

  return (
    <>
      <span className='exchange-rate-timer-description'>
        {duration === 0
          ? i18n.getMessage('currencyExchange.form.timer.updatingRates')
          : i18n.getMessage('currencyExchange.form.timer.description')}
      </span>
      <div className='exchange-rate-timer'>
        <CountdownCircleTimer
          key={key}
          isPlaying={!!remainingTime}
          duration={60}
          initialRemainingTime={remainingTime}
          colors={[styles.activeDefaultColor, styles.activeDefaultColor, styles.errorColor, styles.errorColor]}
          // colors={['#8F27C7', '#8F27C7',  '#D34F6F', '#D34F6F']}
          colorsTime={[60, 20, 10, 0]}
          onComplete={onComplete}
          size={60}
          strokeWidth={4}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </>
  );
};

CountdownTimer.propTypes = {
  expiresAt: PropTypes.string,
  quoteId: PropTypes.string,
  onComplete: PropTypes.func
};

export default CountdownTimer;
