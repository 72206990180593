import { useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ChangePassword } from './ChangePassword';
import { Email } from './Email';
import { EmailConfirmation } from './EmailConfirmation';
import { ExpiredLink } from './ExpiredLink';
import Phone from './Phone';
import Sms from '../Authorization/Sms';
import { PASSWORD_RECOVERY_STATUSES } from 'components/common/constants';
import Loader from 'components/common/Loader';
import 'components/Authorization/Login.scss';

const PasswordRecoveryStages = ({ passwordRecoveryStore }) => {
  let { tokenId } = useParams();
  const { error, generalFields, isLoading, recoveryLinkError, recoveryStatus, values } = passwordRecoveryStore;

  useEffect(() => {
    if (tokenId) {
      passwordRecoveryStore.getPasswordRecoveryLinkData(tokenId);
    }
  }, [passwordRecoveryStore, tokenId]);

  switch (recoveryStatus) {
    case PASSWORD_RECOVERY_STATUSES.EMAIL_CHECK: {
      const onEmailSubmit = (username) => {
        passwordRecoveryStore.setUsername(username);
        passwordRecoveryStore.sendEmail();
      };
      return (
        <Email
          onSubmit={onEmailSubmit}
          error={error}
          recoveryLinkError={recoveryLinkError}
          userName={values?.username}
        />
      );
    }
    case PASSWORD_RECOVERY_STATUSES.EMAIL_CONFIRM: {
      const onBackToEmail = () => passwordRecoveryStore.setRecoveryStatus(PASSWORD_RECOVERY_STATUSES.EMAIL_CHECK);
      const handleCode = (code, disableSend) => {
        passwordRecoveryStore.setCode(code);
        if (!disableSend) {
          passwordRecoveryStore.sendEmailCode();
        }
      };
      const resendSecurityCode = () => passwordRecoveryStore.resendEmailCode();

      return (
        <EmailConfirmation
          email={values?.username}
          backToEmail={onBackToEmail}
          error={error}
          code={values?.code}
          handleCode={handleCode}
          resendCode={resendSecurityCode}
        />
      );
    }
    case PASSWORD_RECOVERY_STATUSES.PASSWORD_SETUP: {
      const onSubmit = (fieldData) => {
        passwordRecoveryStore.setCode(fieldData);
        passwordRecoveryStore.sendPasswordChange();
      };

      return (
        <ChangePassword
          onSubmitStep={onSubmit}
          setPassword={(password) => passwordRecoveryStore.setPassword(password)}
          error={error}
        />
      );
    }
    case PASSWORD_RECOVERY_STATUSES.PHONE_CONFIRM: {
      const handleCode = (code, disableSend) => {
        passwordRecoveryStore.setCode(code);
        if (!disableSend) {
          passwordRecoveryStore.sendPhoneCode();
        }
      };
      const resendSecurityCode = () => passwordRecoveryStore.resendPhoneCode();
      return (
        <Sms
          phone={generalFields.phoneNumber}
          sms={values?.code}
          error={error}
          handleSms={handleCode}
          resendCode={resendSecurityCode}
          isForgotPassword={true}
        />
      );
    }
    default: {
      return tokenId && isLoading && !recoveryLinkError ? (
        <Loader />
      ) : tokenId && !isLoading && recoveryLinkError ? (
        <ExpiredLink />
      ) : (
        <Phone store={passwordRecoveryStore} />
      );
    }
  }
};

PasswordRecoveryStages.propTypes = {
  passwordRecoveryStore: MobXPropTypes.observableObject
};

export default inject('passwordRecoveryStore')(observer(PasswordRecoveryStages));
