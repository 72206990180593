import { Outlet, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { BackButton } from 'components/common/BackButton/BackButton';
import { ROUTE_PATHS } from 'routes/constants';

const Templates = () => {
  const location = useLocation();

  return (
    <section className={'right-section'}>
      {location.pathname !== ROUTE_PATHS.TEMPLATES && <BackButton />}
      <Outlet />
    </section>
  );
};

Templates.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(Templates));
