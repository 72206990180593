import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { RECIPIENT_TYPES, STATIC_COUNTRY_CODES } from 'components/common/constants';
import { Container } from 'uikit/Container/Container';
import {
  BIC_REG_EXP,
  GBP_SORT_CODE_REG_EXP,
  getLatinLettersDigitsSymbolsRegExp,
  LATIN_LETTERS_AND_DIGITS_REG_EXP,
  LATIN_LETTERS_REG_EXP,
  ONLY_DIGITS_REG_EXP
} from 'components/common/PaymentForm/ProviderForms/ModelValidators/utils';
import { formatSortCode } from 'services/utils';
import Button from 'uikit/Button/Button';
import { IconButton } from 'uikit/IconButton/IconButton';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './NewTemplateForm.scss';

const formTabType = {
  RECIPIENT_INFO: 'RECIPIENT_INFO',
  BANK_INFO: 'BANK_INFO'
};

export const NewTemplateForm = () => {
  const i18n = useContext(i18nContext);
  const { id } = useParams();
  const [formTab, setFormTab] = useState(formTabType.RECIPIENT_INFO);

  const form = useFormik({
    initialValues: {
      template_name: '',
      recipient_type: RECIPIENT_TYPES.COMPANY,
      company_name: '',
      first_name: '',
      last_name: '',
      beneficiary_country: '',
      registration_number: '',
      beneficiary_city: '',
      beneficiary_post_code: '',
      beneficiary_address: '',
      recipient_wallet: '',
      account_number: '',
      sort_code: '',
      bank_country: '',
      bank_name: '',
      bic: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      template_name: Yup.string()
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' })),
      recipient_type: Yup.string(),
      company_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === 'Company') {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(getLatinLettersDigitsSymbolsRegExp('.,-'), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: '“.“, “,“, “-“'
                })
              });
          }
        }),
      first_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      last_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .nullable(true)
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      beneficiary_country: Yup.string(),
      registration_number: Yup.string()
        .nullable(true)
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
        .matches(ONLY_DIGITS_REG_EXP, { message: i18n.getMessage('client.validation.message.onlyDigits') }),
      beneficiary_city: Yup.string()
        .nullable(true)
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
        .matches(LATIN_LETTERS_REG_EXP, { message: i18n.getMessage('client.validation.message.isOnlyLatinLetters') }),
      beneficiary_post_code: Yup.string()
        .nullable(true)
        .max(12, i18n.getMessage('error.fieldSize', { amount: '12' }))
        .matches(getLatinLettersDigitsSymbolsRegExp('-'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“-“'
          })
        }),
      beneficiary_address: Yup.string()
        .nullable(true)
        .max(250, i18n.getMessage('error.fieldSize', { amount: '250' }))
        .matches(getLatinLettersDigitsSymbolsRegExp('.,'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“.“, ","'
          })
        }),
      recipient_wallet: Yup.string()
        .nullable(true)
        .max(34, i18n.getMessage('client.validation.message.maxSize', { size: 34 }))
        .trim()
        .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
        }),
      account_number: Yup.string()
        .nullable(true)
        .max(8, i18n.getMessage('error.fieldSize', { amount: '8' })),
      sort_code: Yup.string()
        .nullable(true)
        .max(8, i18n.getMessage('error.fieldSize', { amount: '8' }))
        .matches(GBP_SORT_CODE_REG_EXP, { message: i18n.getMessage('client.validation.message.sortCodeFormat') }),
      bank_country: Yup.string(),
      bank_name: Yup.string()
        .nullable(true)
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
        .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
        }),
      bic: Yup.string()
        .nullable(true)
        .max(12, i18n.getMessage('error.fieldSize', { amount: '12' }))
        .matches(BIC_REG_EXP, { message: i18n.getMessage('client.validation.message.bic') })
    }),
    onSubmit: (values) => {
      //TODO: submit logic
    }
  });

  const { values, handleSubmit, handleChange, setFieldValue, errors, isSubmitting } = form;

  useEffect(() => {
    if (
      ((values.recipient_type === RECIPIENT_TYPES.COMPANY && errors.company_name) ||
        (values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && errors.first_name && errors.last_name)) &&
      formTab !== formTabType.RECIPIENT_INFO
    ) {
      setFormTab(formTabType.RECIPIENT_INFO);
    }
  }, [isSubmitting, errors.company_name, errors.first_name, errors.last_name]);

  const handleDropDownInputChange = (name, data) => handleChange({ target: { name, value: data } });

  const onSortCodeChange = (e) => {
    let inputValue = e.target.value;
    let numericValue = formatSortCode(inputValue);
    setFieldValue('sort_code', numericValue);
  };

  return (
    <Container
      className={'templates-container'}
      header={i18n.getMessage(id ? 'container.editTemplate' : 'container.createTemplate')}
    >
      <div className={'template-form-wrapper'}>
        <form className={'template-form'} onSubmit={handleSubmit}>
          <div className='template-inputs-evenly-wrapper'>
            <Input
              className={'template-form-input'}
              isRequired={true}
              label={i18n.getMessage('templates.form.templateName.label')}
              name={'template_name'}
              value={values.template_name}
              error={errors.template_name}
              onChange={handleChange}
            />
          </div>
          <div className={'template-form-tabs-wrapper'}>
            <IconButton
              className={'template-form-tab-button'}
              text={i18n.getMessage('templates.form.tabs.companyDetails')}
              active={formTab === formTabType.RECIPIENT_INFO}
              onClick={() => setFormTab(formTabType.RECIPIENT_INFO)}
            />
            <IconButton
              className={'template-form-tab-button'}
              text={i18n.getMessage('templates.form.tabs.bankAccounts')}
              active={formTab === formTabType.BANK_INFO}
              onClick={() => setFormTab(formTabType.BANK_INFO)}
            />
          </div>
          {formTab === formTabType.RECIPIENT_INFO ? (
            <>
              <div className={`template-inputs-row-wrapper ${values.recipient_type}`}>
                <InputDropDown
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.recipientType.label')}
                  name={'recipient_type'}
                  value={values.recipient_type}
                  onChange={handleDropDownInputChange}
                  options={Object.values(RECIPIENT_TYPES).map((value) => ({ key: value, value }))}
                />
                {values.recipient_type === RECIPIENT_TYPES.COMPANY && (
                  <Input
                    className={'template-form-input'}
                    isRequired={true}
                    label={i18n.getMessage('templates.form.companyName.label')}
                    name={'company_name'}
                    value={values.company_name}
                    error={errors.company_name}
                    onChange={handleChange}
                  />
                )}
                {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
                  <Input
                    className={'template-form-input'}
                    isRequired={true}
                    label={i18n.getMessage('templates.form.firstName.label')}
                    name={'first_name'}
                    value={values.first_name}
                    error={errors.first_name}
                    onChange={handleChange}
                  />
                )}
                {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
                  <Input
                    className={'template-form-input'}
                    isRequired={true}
                    label={i18n.getMessage('templates.form.lastName.label')}
                    name={'last_name'}
                    value={values.last_name}
                    error={errors.last_name}
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className='template-inputs-row-wrapper'>
                <InputDropDownSearch
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.beneficiaryCountry.label')}
                  name={'beneficiary_country'}
                  value={values.beneficiary_country}
                  onChange={handleDropDownInputChange}
                  options={STATIC_COUNTRY_CODES.map((value) => ({
                    key: value,
                    value: i18n.getMessage(`country.${value}`)
                  }))}
                  error={errors.beneficiary_country}
                />
                <Input
                  className={'template-form-input'}
                  label={
                    values.recipient_type === RECIPIENT_TYPES.COMPANY
                      ? i18n.getMessage('templates.form.registrationNumber.label')
                      : i18n.getMessage('templates.form.identificationNumber.label')
                  }
                  name={'registration_number'}
                  value={values.registration_number}
                  onChange={handleChange}
                  error={errors.registration_number}
                />
              </div>
              <div className='template-inputs-row-wrapper'>
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.beneficiaryCity.label')}
                  name={'beneficiary_city'}
                  value={values.beneficiary_city}
                  onChange={handleChange}
                  error={errors.beneficiary_city}
                />
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.beneficiaryPostalCode.label')}
                  name={'beneficiary_post_code'}
                  value={values.beneficiary_post_code}
                  onChange={handleChange}
                  error={errors.beneficiary_post_code}
                />
              </div>
              <div className='template-inputs-evenly-wrapper'>
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.beneficiaryAddress.label')}
                  name={'beneficiary_address'}
                  value={values.beneficiary_address}
                  onChange={handleChange}
                  error={errors.beneficiary_address}
                />
              </div>
            </>
          ) : (
            <>
              <div className='template-inputs-evenly-wrapper'>
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.recipientIban.label')}
                  name={'recipient_wallet'}
                  value={values.recipient_wallet}
                  error={errors.recipient_wallet}
                  onChange={handleChange}
                />
              </div>
              <div className='template-inputs-row-wrapper'>
                <Input
                  className={'template-form-input'}
                  name={'account_number'}
                  label={i18n.getMessage('templates.form.accountNumberGBP.label')}
                  value={values.account_number}
                  error={errors.account_number}
                  onChange={handleChange}
                  max={8}
                />
                <Input
                  className={'template-form-input'}
                  name={'sort_code'}
                  label={i18n.getMessage('templates.form.sortCode.label')}
                  value={values.sort_code}
                  error={errors.sort_code}
                  onChange={onSortCodeChange}
                  max={8}
                />
              </div>
              <div className='template-inputs-row-wrapper'>
                <InputDropDownSearch
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.bankCountry.label')}
                  name={'bank_country'}
                  value={values.bank_country}
                  onChange={handleDropDownInputChange}
                  options={STATIC_COUNTRY_CODES.map((value) => ({
                    key: value,
                    value: i18n.getMessage(`country.${value}`)
                  }))}
                  error={errors.bank_country}
                />
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.bankName.label')}
                  name={'bank_name'}
                  value={values.bank_name}
                  onChange={handleChange}
                  error={errors.bank_name}
                />
              </div>
              <div className='template-inputs-evenly-wrapper'>
                <Input
                  className={'template-form-input'}
                  label={i18n.getMessage('templates.form.BIC.label')}
                  name={'bic'}
                  value={values.bic}
                  error={errors?.bic}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          <div className='template-inputs-row-wrapper'>
            <Button type={'primary'} roleType={'submit'} size={'large'}>
              {i18n.getMessage('templates.form.button')}
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

NewTemplateForm.propTypes = {
  transactionsStore: MobXPropTypes.observableObject,
  userWallets: PropTypes.array
};

export default inject((stores) => ({
  userWallets: stores.userStore?.userWallets,
  transactionsStore: stores.transactionsStore
}))(observer(NewTemplateForm));
