import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import LatestPaymentObligationsList from './LatestPaymentObligationsList';
import i18nContext from 'components/i18n-context';
import { Container } from 'uikit/Container/Container';
import './LatestPaymentObligations.scss';

const LatestPaymentObligations = ({ paymentObligationsStore }) => {
  const i18n = useContext(i18nContext);

  return (
    <section className='right-section latest-payment-obligations'>
      <Container className='payment-obligations-container' header={i18n.getMessage('container.paymentObligations')}>
        <LatestPaymentObligationsList
          obligations={paymentObligationsStore?.obligationList}
          isLoading={paymentObligationsStore.isLoading}
        />
      </Container>
    </section>
  );
};

LatestPaymentObligations.propTypes = {
  paymentObligationsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  paymentObligationsStore: stores.paymentObligationsStore
}))(observer(LatestPaymentObligations));
