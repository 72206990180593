import { makeAutoObservable, runInAction } from 'mobx';
import { getPaymentObligationsList } from 'services/requestAgent';
import { PAYMENT_OBLIGATION_STATUSES } from '../components/common/constants';

class PaymentObligationsStore {
  isLoading = false;
  error = null;
  obligationList = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  async getPaymentObligationsList(customerNumber) {
    try {
      this.setIsLoading(true);
      const { content: data } = await getPaymentObligationsList(customerNumber, {
        statuses: [PAYMENT_OBLIGATION_STATUSES.OPEN]
      });

      runInAction(() => {
        this.obligationList = data;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default new PaymentObligationsStore();
