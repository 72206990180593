/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { CONFIRMATION_HANDLE_TYPES, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { isFullSecurityCode } from 'components/utils';
import { getConfirmationActionType, getSecureUserPhone } from 'services/authUtils';
import { amountFormattedValue, getErrorMessageForAlert } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const getTransferDetailsFields = (i18n, transferData, isCurrency = false, isBetweenAccounts = false) => {
  if (isBetweenAccounts) {
    return [
      {
        label: i18n.getMessage('betweenAccounts.form.from'),
        value: transferData.from
      },
      {
        label: i18n.getMessage('betweenAccounts.form.to'),
        value: transferData.to
      },
      {
        label: i18n.getMessage('betweenAccounts.form.amount'),
        value: transferData.amount
      },
      {
        label: i18n.getMessage('betweenAccounts.form.currency'),
        value: transferData.currency
      },
      {
        label: i18n.getMessage('betweenAccounts.form.description'),
        value: transferData.description
      }
    ].filter((field) => field.value || field.value === 0);
  }

  if (isCurrency) {
    return [
      {
        label: i18n.getMessage('currencyExchange.form.from'),
        value: transferData?.accountFrom?.iban?.iban || transferData?.accountFrom?.multi_iban?.iban
      },
      {
        label: i18n.getMessage('currencyExchange.form.sentAmount'),
        value: amountFormattedValue(transferData?.amountFrom)
      },
      {
        label: i18n.getMessage('currencyExchange.form.currency'),
        value: transferData?.accountFrom?.currency
      },
      {
        label: i18n.getMessage('currencyExchange.form.to'),
        value: transferData?.accountTo?.iban?.iban || transferData?.accountTo?.multi_iban?.iban
      },
      {
        label: i18n.getMessage('currencyExchange.form.receivedAmount'),
        value: amountFormattedValue(transferData?.amountTo)
      },
      {
        label: i18n.getMessage('currencyExchange.form.currency'),
        value: transferData?.accountTo?.currency
      },
      {
        label: i18n.getMessage('currencyExchange.form.yourRate'),
        value: `${transferData?.rate} ${transferData?.accountFrom?.currency}/${transferData?.accountTo?.currency}`
      },
      {
        label: i18n.getMessage('transfer.confirmation.label.commission'),
        value: `${amountFormattedValue(transferData.commission)} ${transferData?.accountFrom?.currency}`
      }
    ].filter((field) => field.value || field.value === 0);
  }

  return [
    {
      label: i18n.getMessage('transfer.confirmation.label.senderName'),
      value: transferData.sender_name
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.senderAccount'),
      value: transferData.iban
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientName'),
      value: transferData.counterparty.party_name
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientAccount'),
      value: transferData.counterparty.iban
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientBankCountry'),
      value: transferData.counterparty.bank_country
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientBankName'),
      value: transferData.counterparty.bank_name
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientBic'),
      value: transferData.counterparty.bic
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientAccountNumber'),
      value: transferData.counterparty.account_number
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientSortCode'),
      value: transferData.counterparty.sort_code
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.registrationNumber'),
      value: transferData.counterparty?.registration_number
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientAddress'),
      value: transferData.counterparty?.party_address
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientCity'),
      value: transferData.counterparty?.party_city
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.recipientCountry'),
      value: transferData.counterparty?.party_country
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.amount'),
      value: `${amountFormattedValue(transferData.amount)} ${transferData.currency_code}`
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.commission'),
      value: `${amountFormattedValue(transferData.total_commissions)} ${transferData.currency_code}`
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.commentToTransfer'),
      value: transferData.comment
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.paymentMethod'),
      value: i18n.getMessage(`transfer.paymentMethod.${transferData.payment_method}`)
    },
    {
      label: i18n.getMessage('transfer.confirmation.label.files'),
      value: transferData.uploadedFiles?.map((file) => file.name).join(',\n ')
    }
  ].filter((field) => field.value || field.value === 0);
};

const TransferConfirmationScheme = ({
  isLoading,
  transferData,
  onClose,
  onConfirm,
  error,
  clearError,
  isCurrency,
  isBetweenAccounts,
  generateSecurityCode,
  resendSecurityCode
}) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

  useEffect(() => {
    if (isCurrency && !showConfirmationCode) {
      handleConfirm();
    }
  }, []);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setIsConfirmDisabled(true);
    } else {
      setAlertState({ type: '', message: '' });
    }
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
    return () => {
      clearError();
    };
  }, []);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    if (!isFullSecurityCode(code)) {
      setIsConfirmDisabled(true);
    } else {
      setIsConfirmDisabled(false);
      setAlertState({ type: '', message: '' });
    }
  };

  const handleConfirm = () => {
    if (!showConfirmationCode) {
      setShowConfirmationCode(true);
      if (getConfirmationActionType()) {
        setIsConfirmDisabled(true);
        if (!isCurrency) {
          generateSecurityCode();
        }
      }
      return;
    }
    onConfirm(securityCode);
  };

  const handleEdit = () => {
    if (showConfirmationCode) {
      setShowConfirmationCode(false);
      return;
    }
    onClose();
  };

  const schemeLine = (label, value, index) => {
    return (
      <div key={`${label}-${index}`} className={'scheme-line'}>
        <p className={'left'}>{i18n.getMessage(label) + ':'}</p>
        <p className={'center'}>{value}</p>
      </div>
    );
  };

  return (
    <div className={'scheme-wrapper transfer-confirmation'}>
      <div className={'scheme-header'}>
        <p className={'account'}>{i18n.getMessage('popUp.paymentConfirmation')}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>
      <div className={'scheme-line-wrapper'}>
        {getTransferDetailsFields(i18n, transferData, isCurrency, isBetweenAccounts).map((field, index) =>
          schemeLine(field.label, field.value, index)
        )}
      </div>
      {showConfirmationCode && (
        <ConfirmCodeWrapper
          PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
          isDisabled={isDisabled}
          error={!error?.type && error}
          onComplete={onComplete}
          showCountDown={showCountDown}
          handleResendCode={handleResendCode}
          onTimerEnd={onTimerEnd}
          alertState={alertState}
          confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
        />
      )}
      <div className={`buttons-wrapper ${showConfirmationCode ? 'single' : ''}`}>
        {!showConfirmationCode && (
          <Button type={'outline'} size={'large'} onClick={handleEdit}>
            {i18n.getMessage('transferConfirmation.button.editTransfer')}
          </Button>
        )}
        <Button
          type={'primary'}
          size={'large'}
          roleType={'submit'}
          isDisabled={
            isLoading ||
            isConfirmDisabled ||
            ['CONFIRMATION_CODE_IS_OUT_OF_DATE', 'CONFIRMATION_CODE_TRIES_EXCEED'].includes(error?.code)
          }
          onClick={handleConfirm}
        >
          {i18n.getMessage('transferConfirmation.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

TransferConfirmationScheme.propTypes = {
  isLoading: PropTypes.bool,
  transferData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.any,
  isCurrency: PropTypes.bool,
  isBetweenAccounts: PropTypes.bool,
  generateSecurityCode: PropTypes.func,
  resendSecurityCode: PropTypes.func.isRequired
};

export default TransferConfirmationScheme;
