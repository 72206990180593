export const UNVERIFIED_ROUTE_PATHS = {
  VERIFICATION: '/verification',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  MESSAGES: '/messages',
  MESSAGES_TOPIC: '/messages/:id',
  MESSAGES_CREATE_NEW_TOPIC: '/messages/create-new-topic'
};

export const UNAUTHORIZED_ROUTE_PATHS = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  CUSTOMER_REGISTRATION: '/registration/customer',
  REGISTRATION_SUCCESS: '/registration/customer/success',
  PASSWORD_RECOVERY: '/password_recovery'
};

export const ROUTE_PATHS = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  NEW_PAYMENT: '/new-payment',
  CURRENCY_EXCHANGE: '/currency-exchange',
  BETWEEN_ACCOUNTS: '/between-accounts',
  ACCOUNTS: 'accounts',
  CARDS: '/cards',
  CARDS_CREATE: '/cards/create',
  CARDS_CREATE_VIRTUAL: '/cards/create/virtual',
  CARDS_CREATE_PHYSICAL: '/cards/create/physical',
  CARD_SETTINGS: '/cards/:id',
  CARDS_AUTHORIZATIONS: '/cards/authorizations',
  TRANSACTIONS: '/transactions',
  INVOICES: '/invoices',
  FEES: '/fees',
  TEMPLATES: '/templates',
  TEMPLATES_CREATE: '/templates/create',
  TEMPLATES_EDIT: '/templates/:id',
  REPRESENTATIVES: '/representatives',
  ...UNVERIFIED_ROUTE_PATHS,
  ...UNAUTHORIZED_ROUTE_PATHS
};

export const INITIAL_PATH_AFTER_LOGIN = ROUTE_PATHS.ROOT;
