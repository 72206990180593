import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import AuthorizationsFilter from './AuthorizationsFilter';
import AuthorizationsTable from './AuthorizationsTable';
import { EmptyAuthorizationsList } from './EmptyAuthorizationsList';
import Pagination from 'components/common/Pagination/Pagination';
import i18nContext from 'components/i18n-context';
import { filterWallesterAccounts } from 'components/Cards/utils';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import './AuthorizationsList.scss';

const AuthorizationsList = ({ customerNumber, cardsStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  useEffect(() => {
    const wallesterAccounts = filterWallesterAccounts(userStore.userAccounts);

    cardsStore.initializeStoreState(customerNumber, wallesterAccounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.userAccounts]);

  useEffect(() => {
    if (customerNumber) {
      cardsStore.getCardAuthorizations(customerNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerNumber,
    cardsStore.filters.from_date,
    cardsStore.filters.to_date,
    cardsStore.filters.wallets,
    cardsStore.filters.statuses,
    cardsStore.authorizationsSort.sort_column,
    cardsStore.authorizationsSort.sort_direction,
    cardsStore.pagination.size,
    cardsStore.pagination.page
  ]);

  const handleSortChange = (sortData) => cardsStore.setAuthorizationsSort(sortData);

  const handleSetCurrentPage = (pageNumber) => {
    cardsStore.setAuthorizationsPage(pageNumber);
    cardsStore.getCardAuthorizations(customerNumber);
  };

  const handleSetPageSize = (size) => {
    cardsStore.setAuthorizationsPageSize(size);
  };

  return (
    <section className='latest-authorizations'>
      <Container className='authorizations-container' header={i18n.getMessage('container.authorizations')}>
        <AuthorizationsFilter isMobile={isMobile} />
        {cardsStore.authorizationsList.length ? (
          <>
            <AuthorizationsTable
              isLoading={cardsStore.isLoading}
              onChangeSort={handleSortChange}
              authorizations={cardsStore?.authorizationsList}
              sortData={cardsStore?.authorizationsSort}
            />
            <Pagination
              isShowPaginationSize={true}
              paginationSize={cardsStore.pagination.size}
              handleChangePaginationSize={handleSetPageSize}
              isLoading={cardsStore.isLoading}
              currentPage={cardsStore.pagination.page}
              numberOfPages={cardsStore.totalPages}
              setCurrentPage={handleSetCurrentPage}
            />
          </>
        ) : (
          <EmptyAuthorizationsList />
        )}
      </Container>
    </section>
  );
};

AuthorizationsList.propTypes = {
  customerNumber: PropTypes.string,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  cardsStore: stores.cardsStore,
  userStore: stores.userStore
}))(observer(AuthorizationsList));
