import { useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CardDashboardView } from './CardDashboardView';
import { ReactComponent as AddNewCardIcon } from 'assets/add-new-card-icon.svg';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import '../../../Cards.scss';

const CardsView = ({ customerType, cardsPerAccountLimit, cardsPerCustomerLimit, openCardDetails, cardsStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const cardsWrapperRef = useRef(null);
  const [paddingValue, setPaddingValue] = useState('0');
  const isCardLimitPerAccountExceeded =
    // eslint-disable-next-line max-len
    (cardsStore?.cards?.filter((card) => card?.wallet_number === cardsStore.selectedAccount?.wallet_number) || [])
      .length >= cardsPerAccountLimit;
  const isCardLimitPerCustomerExceeded = cardsStore?.cards?.length >= cardsPerCustomerLimit;

  useEffect(() => {
    if (cardsWrapperRef.current) {
      const wrapperHeight = cardsWrapperRef.current.clientHeight;
      if (wrapperHeight > 390 && isMobile) {
        setPaddingValue('10px');
      } else {
        setPaddingValue('0');
      }
    }
  }, [cardsStore.selectedAccount, isMobile]);

  return (
    <Container className={'container-cards'} header={i18n.getMessage('cards.container.cards')} isMaxWidthUnset={true}>
      <Link
        className={`link-normal get-new-card-link ${
          isCardLimitPerAccountExceeded || isCardLimitPerCustomerExceeded ? 'disabled' : ''
        }`}
        data-tooltip-id={'disabled-tooltip'}
        to={ROUTE_PATHS.CARDS_CREATE}
        onClick={(e) => {
          if (isCardLimitPerAccountExceeded || isCardLimitPerCustomerExceeded) {
            e.preventDefault();
          }
        }}
      >
        <AddNewCardIcon />
        {i18n.getMessage('cards.newCard.title')}
      </Link>
      {(isCardLimitPerCustomerExceeded || isCardLimitPerAccountExceeded) && (
        <ReactTooltip
          id={'disabled-tooltip'}
          place={'bottom'}
          content={
            isCardLimitPerCustomerExceeded
              ? i18n.getMessage('cards.newCard.disabled.perCustomer')
              : i18n.getMessage('cards.newCard.disabled.perAccount')
          }
        />
      )}
      <div className={'cards-wrapper'} style={{ paddingLeft: paddingValue }} ref={cardsWrapperRef}>
        {cardsStore.cards.map(
          (card, index) =>
            card?.wallet_number === cardsStore.selectedAccount?.wallet_number && (
              <CardDashboardView key={index} customerType={customerType} card={card} handleClick={openCardDetails} />
            )
        )}
      </div>
    </Container>
  );
};

CardsView.propTypes = {
  customerType: PropTypes.string,
  cardsPerAccountLimit: PropTypes.number,
  cardsPerCustomerLimit: PropTypes.number,
  openCardDetails: PropTypes.func,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerType: stores.userStore.userData.account?.type,
  cardsPerAccountLimit: stores.userStore.userData.account?.cards_per_account_limit,
  cardsPerCustomerLimit: stores.userStore.userData.account?.cards_per_customer_limit,
  cardsStore: stores.cardsStore
}))(observer(CardsView));
